import React, { createContext, useState } from 'react';

// Create a Context for the ClientId
const AdminNavIdContext = createContext();

const AdminNavIdProvider = ({ children }) => {
  const [adminNavId, setAdminNavId] = useState(null);

  return (
    <AdminNavIdContext.Provider value={{ adminNavId, setAdminNavId }}>
      {children}
    </AdminNavIdContext.Provider>
  );
};
const useAdminNavId = () => React.useContext(AdminNavIdContext);
export { AdminNavIdProvider, useAdminNavId };
