const HR = 'EXTERNAL-HR';

export const availableRoles = {
  EMPLOYEE: 'EMPLOYEE',
  CANDIDATE: 'CANDIDATE',
  CLIENT: 'EXTERNAL_HR',
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
};

