import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthorizedRoute from '../AuthorizedRoute';
import { useAuth } from 'context/authContext';
import Navbar from 'components/Navbar';

/**
 * Renders the component if the user is authenticated
 *
 * @param {Component} Component
 *
 * @returns {JSX}
 */
const renderComponent = (Component) => (props) => {
  return <Component {...props} />;
};

/**
 * This Component returns a new
 * route based on authenticated status
 *
 * @returns {JSX}
 */
const AuthenticatedRoute = (props) => {
  const { user } = useAuth();
  const { component: Component, ...rest } = props;

  if (!user?.isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  return (
    <Navbar>
      <AuthorizedRoute {...rest} render={renderComponent(Component)} />
    </Navbar>
  );
};

export default AuthenticatedRoute;
