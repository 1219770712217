import styled from 'styled-components';

export const ModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.25);
  z-index: 4;
  display: grid;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 500px;
  height: 100vh;
  width: 100%;
  padding: 50px 0;
  align-items: center;
  justify-items: center;
  transition: 0.3s;
`;

export const ModalBody = styled.div`
  background: #fff;
  border-radius: 2px;
  width: ${({ width }) => width ?? '45%'};
  margin: auto;
  grid: 70px auto ${(props) => (props.footer ? '108px' : '')} / minmax(auto, 540px);
  max-height: 85vh;
  overflow: auto;
  transition: 0.3s;
  z-index: 2;
`;

export const ModalHeader = styled.div`
  padding: 30px;
  display: grid;
  grid: 1fr / repeat(2, 1fr);
  font-size: 18px;
  font-weight: bold;
  grid-template-columns: 80% 20%;
`;

export const ModalTitle = styled.div`
  display: grid;
  align-content: center;
`;

export const ModalContent = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr;
`;

export const ModalFooter = styled.div`
  padding: 30px;
`;

export const ActionContainer = styled.div`
  text-align: center;
`;

export const ActionContainerTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  margin: 20px 0px;
`;

export const ActionContainerSubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #666666;
  margin: 20px 0px;
`;

export const DeleteActionText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #eb5757;
  cursor: pointer;
`;
