import React from 'react';
import { Timelinecontainer } from './styles';
import { useQuery } from '@apollo/client';
import { GET_TIMELINE_LIST } from '../../graphql/queries/client/requests';
import { formatDate } from 'utils/dateFormatter';

const Timeline = ({ data: timelineData }, name) => {
  const { loading, error, data } = useQuery(GET_TIMELINE_LIST, {
    variables: {
      optionId: timelineData.id,
      verificationId: timelineData.verificationId
    }
  });

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  return (
    <Timelinecontainer>
      {data?.timelineList?.results &&
        data.timelineList.results.map((item, index) => {
          return (
            <div key={item.id} className="container right">
              <div className="content">
                <h2> {item.action} </h2>
                <p>{formatDate(item.updatedAt)}</p>
              </div>
            </div>
          );
        })}
    </Timelinecontainer>
  );
};

export default Timeline;
