import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
0% {
  -webkit-transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
}
`;

const FullPage = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Small = css`
  width: 20px;
  height: 20px;
`;

export const LoaderContainer = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #34b991;
  width: 50px;
  height: 50px;
  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
  ${({ fullPage }) => (fullPage ? FullPage : Small)}

  .loader {
    width: 50px;
    height: 50px;
  }
  }
`;
