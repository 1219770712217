import axios from 'axios';
import FileSaver from 'file-saver';
/**
 * This function dowloads files
 * from server
 *
 * @param {*} fileUrl
 * @param {*} fileName
 *
 * @returns {void}
 */
export const fileDownloader = async (fileUrls = [], fileName) => {
  fileUrls.map(async (fileUrl) => {
    const res = await axios.get(`https://cors-anywhere.herokuapp.com/${fileUrl}`, {
      responseType: 'blob',
    });
    fileSaver(res.data, fileName);
  });
};

export const fileSaver = (fileData, fileName) => FileSaver.saveAs(fileData, fileName);
