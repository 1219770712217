/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InputContainer, Form } from '../../Admin/Clients/styles';
import { SelectDropDown, Label } from 'pages/ViewSingleRequest/styles';
import { CREATE_NYSC } from 'graphql/resolvers/admin/candidates/mutations';
import { AcademicForm, FormFooter } from '../styles';
import { Error } from 'pages/Signin/styles';
import { toastSuccess, toastError } from 'components/common/Toast';
import FormUploader from 'components/Uploader';
import { renameFileUpload } from 'utils/renameUploadUtils';
import { nyscValidationSchema } from 'helpers/validators';

import Container from 'components/common/Container';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import { svgs } from 'assets';

const Nysc = ({
  nextTab,
  prevTab,
  newInfo,
  setNewinfo,
  index,
  savedData,
  fileName,
  ...rest
}) => {
  const checkFile = (file) => {
    if (file) {
      return file;
    } else {
      return null;
    }
  };

  const [showLoader, setLoaderState] = useState(false);
  const [certificate, setCertificate] = useState(
    savedData[savedData.length - 1]?.upload || checkFile(newInfo?.nysc?.upload)
  );
  const [showCertError, setShowCertError] = useState(false);

  const [createNyscRequest] = useMutation(CREATE_NYSC);
  const handleCertificate = (files) => {
    const modifiedFile = files[0];
    // const modifiedFile = renameFileUpload(file, fileName || 'NYSC_Certificate');
    setCertificate(modifiedFile);
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      showDate: true,
      callNumber: '',
      upload: '',
      certificateNumber: '',
      dateStarted: '',
      dateCompleted: '',
      certificate_type: '',
      exemption_grounds: '',
      ...savedData[savedData.length - 1],
      ...newInfo?.nysc
    },
    validationSchema: Yup.object({
      ...nyscValidationSchema
    }),
    onSubmit: async (values) => {
      setLoaderState(true);
      setShowCertError(false);

      if (!certificate) setShowCertError(true);
      const exemption =
        values.certificate_type !== 'NYSC EXEMPTION CERTIFICATE'
          ? { groundsForExemption: 'AGE' }
          : { groundsForExemption: values.exemption_grounds };
      const yearPayload = values.showDate
        ? { dateStarted: values.dateStarted, dateCompleted: values.dateCompleted }
        : { dateStarted: '1111-01-10', dateCompleted: '1111-01-10' };

      const option = {
        verificationRequest: localStorage.getItem('verificationId'),
        id: values.id ? values.id : null,
        callNumber: values.callNumber,
        certificateNumber: values.certificateNumber,
        certificateType: values.certificate_type,
        upload: !certificate ? newInfo?.nysc?.upload : certificate
      };

      if (certificate) {
        try {
          const response = await createNyscRequest({
            variables: {
              ...option,
              ...yearPayload,
              ...exemption
            }
          });
          const { ok, errors } = response.data.createNyscRequest;
          setLoaderState(false);
          if (errors) {
            const { messages } = errors;
            return toastError('', messages);
          }
          const valueModified = { ...values, upload: certificate };
          if (!errors & ok) {
            setNewinfo((prev) => ({
              ...prev,
              nysc: valueModified
            }));
            toastSuccess(svgs.SUCCESS_ICON, 'NYSC Request Submitted Successfully');
            nextTab(index);
          }
        } catch (error) {
          const { message } = error;
          toastError(svgs.DELETE_ICON, message);
        }
      }
      return setLoaderState(false);
    }
  });

  const DateForm = (props) => (
    <>
      <InputContainer>
        <label htmlFor="dateStarted">Date Started</label>
        <input
          id="dateStarted"
          name="dateStarted"
          type="date"
          placeholder="YYYY-MM-DD"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dateStarted}
          className={`InputElement ${
            formik.touched.dateStarted && formik.errors.dateStarted ? 'Invalid' : ''
          }`}
        />
        {formik.touched.dateStarted && formik.errors.dateStarted ? (
          <Error>{formik.errors.dateStarted}</Error>
        ) : null}
      </InputContainer>
      <InputContainer>
        <label htmlFor="dateCompleted">Date Completed</label>
        <input
          id="dateCompleted"
          name="dateCompleted"
          type="date"
          placeholder="YYYY-MM-DD"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dateCompleted}
          className={`InputElement ${
            formik.touched.dateCompleted && formik.errors.dateCompleted ? 'Invalid' : ''
          }`}
        />
        {formik.touched.dateCompleted && formik.errors.dateCompleted ? (
          <Error>{formik.errors.dateCompleted}</Error>
        ) : null}
      </InputContainer>
    </>
  );

  const isExemption = formik.values.certificate_type === 'NYSC EXEMPTION CERTIFICATE';
  const isExclusion = formik.values.certificate_type === 'NYSC EXCLUSION CERTIFICATE';
  const certificate_type = formik.values.certificate_type;
  useEffect(() => {
    if (formik.values.certificate_type !== 'NYSC EXEMPTION CERTIFICATE') {
      formik.setFieldValue('exemption_grounds', '');
    }
  }, [certificate_type]);
  return (
    <Container>
      <AcademicForm reference>
        <Form onSubmit={formik.handleSubmit}>
          <div style={{ padding: '30px' }}>
            <InputContainer>
              <label htmlFor="callNumber">CALL UP NUMBER</label>
              <input
                id="callNumber"
                name="callNumber"
                type="text"
                placeholder="NYSC Call Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.callNumber}
                className={`InputElement ${
                  formik.touched.callNumber && formik.errors.callNumber ? 'Invalid' : ''
                }`}
              />
              {formik.touched.callNumber && formik.errors.callNumber ? (
                <Error>{formik.errors.callNumber}</Error>
              ) : null}
            </InputContainer>
            <InputContainer>
              <label htmlFor="callNumber">TYPE OF CERTIFICATE</label>
              <div style={{ marginLeft: '1.2rem' }}>
                <SelectDropDown
                  name="certificate_type"
                  width="100%"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value === 'NYSC EXCLUSION CERTIFICATE') {
                      formik.setFieldValue('showDate', false);
                    } else {
                      formik.setFieldValue('showDate', true);
                    }
                    formik.handleChange(e, 'certificate_type');
                  }}
                  value={formik.values.certificate_type}
                >
                  <option>{'Select Nysc certificate type'}</option>
                  <option value="NYSC DISCHARGE CERTIFICATE">
                    NYSC discharge certificate
                  </option>
                  <option value="NYSC EXEMPTION CERTIFICATE">
                    NYSC exemption certificate
                  </option>
                  <option value="NYSC EXCLUSION CERTIFICATE">
                    NYSC exclusion certificate
                  </option>
                </SelectDropDown>
              </div>
              {formik.touched.certificate_type && formik.errors.certificate_type ? (
                <Error>{formik.errors.certificate_type}</Error>
              ) : null}
            </InputContainer>
            {isExemption && (
              <InputContainer padding="15px 0px">
                <Label htmlFor="exemption_grounds">Grounds For Exemption</Label>
                <div style={{ marginLeft: '1.2rem' }}>
                  <SelectDropDown
                    name="exemption_grounds"
                    width="100%"
                    onChange={formik.handleChange}
                    value={formik.values?.exemption_grounds}
                  >
                    <option>Select exemption grounds</option>
                    <option value="AGE">Age</option>
                    <option value="PART TIME STUDIES">Part-time Studies</option>
                  </SelectDropDown>
                </div>
              </InputContainer>
            )}
            <InputContainer>
              <label htmlFor="certificateNumber">Certificate Number</label>
              <input
                id="certificateNumber"
                name="certificateNumber"
                type="text"
                placeholder="Certificate Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.certificateNumber}
                className={`InputElement ${
                  formik.touched.certificateNumber && formik.errors.certificateNumber
                    ? 'Invalid'
                    : ''
                }`}
              />
              {formik.touched.certificateNumber && formik.errors.certificateNumber ? (
                <Error>{formik.errors.certificateNumber}</Error>
              ) : null}
            </InputContainer>
            {!isExclusion && <DateForm {...formik} />}
            <InputContainer>
              <label htmlFor="">Nysc certificate</label>
              <FormUploader
                form
                accept={[
                  'application/pdf',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'image/png',
                  'image/jpg',
                  'image/jpeg'
                ]}
                className={showCertError ? 'Invalid' : ''}
                handleUpload={handleCertificate}
                text="Upload File"
                file={formik?.values?.upload}
              />
              {showCertError ? <Error>Please upload certificate</Error> : null}
            </InputContainer>
          </div>

          <FormFooter>
            <Button backbutton type="button" onClick={() => prevTab(index)}>
              Back
            </Button>
            <Button type="submit" disabled={showLoader ? true : false}>
              {showLoader ? (
                <div style={{ textAlign: 'center' }}>
                  <Loader />
                </div>
              ) : (
                'Next'
              )}
            </Button>
          </FormFooter>
        </Form>
      </AcademicForm>
    </Container>
  );
};

export default Nysc;
