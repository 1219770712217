import { ApolloClient } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { isLoggedIn } from '../helpers/utils';
import { cache } from './cache';
import { resolvers } from './resolvers';
import { typeDefs } from './typeDefs';
import { authUserInitialData } from './resolvers/auth';

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/`
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') ?? '';
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: true,
  assumeImmutableResults: true,
  resolvers,
  typeDefs,
  cache
});

client.onResetStore(() =>
  cache.writeData({
    data: {
      isLoggedIn: isLoggedIn(),
      AuthUser: authUserInitialData
    }
  })
);
