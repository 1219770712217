import React, { useState } from 'react';
import {
  ModalContainer,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from './styles';
import { svgs } from '../../assets';

const Modal = ({ title, children, footer, show = false, setShowModal, ...props }) => {
  return !show ? null : (
    <ModalContainer>
      <ModalBody {...props}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <div style={{ display: 'grid', justifyContent: 'end' }}>
            <img
              src={svgs.CLOSE_ICON}
              style={{ cursor: 'pointer' }}
              alt="close"
              onClick={() => setShowModal(!show)}
            />
          </div>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        {footer ? <ModalFooter>{footer}</ModalFooter> : null}
      </ModalBody>
    </ModalContainer>
  );
};

export default Modal;
