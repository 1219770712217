import React, { useState } from 'react';
import {
  ActionContainer,
  ActionContainerTitle,
  ActionContainerSubTitle,
  DeleteActionText
} from '../styles';
import Loader from 'components/common/Loader';

const ActionView = ({
  title,
  subTitle,
  icon,
  actionText,
  actionLoader,
  actionHandler
}) => {
  return (
    <ActionContainer>
      <div>
        <img src={icon} />
      </div>
      <ActionContainerTitle>{title}</ActionContainerTitle>
      <ActionContainerSubTitle>
        {subTitle ? (
          subTitle
        ) : (
          <p>
            You <b>cannot</b> undo this action
          </p>
        )}
      </ActionContainerSubTitle>
      <hr />
      {!actionLoader ? (
        <DeleteActionText onClick={actionHandler}>{actionText}</DeleteActionText>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loader />
        </div>
      )}
    </ActionContainer>
  );
};

export default ActionView;
