import styled from 'styled-components';

export const ConditionalDiv = styled.div`
  visibility: ${(props) => (props.total > 0 ? 'visible' : 'hidden')};
`;

export const SelectedBox = styled.div`
  background: #fff;
  color: ${({ green, red }) => (green ? '#408996' : red ? '#EB5757' : '#000')};
  width: 150px;
  height: 45px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 550;
  font-size: 12px;
  line-height: 26px;
  position: relative;
  top: 25px;
  margin-right: 2px;
  cursor: pointer;
`;

export const SelectedWrapper = styled.div`
  display: flex;
`;
export const TabContainer = styled.div`
  .tabs {
    list-style: none;
    display: flex;
    border-bottom: 1px solid #ccc;
  }

  .tabs .tab {
    flex-basis: 10%;
    margin: 0.1em;
    padding: 1em;
    cursor: pointer;
    transform: translateY(1px);
    border-radius: 5px 5px 0 0;
    transition: background-color 0.2s ease-in-out;
    border: 1px solid transparent;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
  }

  .tabs .tab:hover {
    background-color: #ececec;
  }

  .tabs .tab.selected {
    border-radius: 5px 5px 0 0;
    border-width: 1px;
    border-color: #ccc #ccc #408996 #ccc;
    border-style: solid;
    color: #408996;
    font-weight: 600;
  }

  .tabs .tab.selected:hover {
    background: none;
  }
`;

export const NavBar = styled.div`
  display: ${({ candidates }) => (candidates ? 'flex' : 'grid')};
  padding: 20px 0;
  grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
  margin: ${({ spacing }) => (spacing ? '30px 0' : '0')};
  align-items: ${({ candidates }) => (candidates ? 'center' : 'none')};
  div {
    flex: 1;
  }
`;

export const EmptyDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  align-items: center;
  div {
    margin-bottom: 45px;
    font-size: 14px;
    color: '#333';
    font-weight: 500;
  }
`;

export const PageTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #333333;
  margin: 40px 0;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 30px;
  margin-bottom: 40px;
`;

export const Card = styled.div`
  display: grid;
  background: #fff;
  // grid-template-columns: 2fr 1fr;
  grid-template-columns: 1fr 50px;
  border-radius: 2px;
  box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
  padding: 20px 20px;
  position: relative;
`;

export const Content = styled.div`
  display: grid;
`;

export const Action = styled.div`
  top: -20px;
  right: px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const ContentStats = styled.div`
  display: flex;
`;

export const ContentEmail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // overflow-wrap: break-word;
  // width: 80%;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;

export const StatLabel = styled.label`
  padding: 4px 10px;
  font-size: 13px;
  background: ${(props) =>
    props.completed ? 'rgba(52, 185, 145, 0.1)' : 'rgba(242, 201, 76, 0.2)'};
  border-radius: 2px;
  color: ${(props) => (props.completed ? '#15A412' : '#F2994A')};
  margin-right: 10px;
`;

export const DropDown = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px #ddd;
  width: ${({ candidates }) => (candidates ? '200px' : '180px')};
  right: -87px;
  top: ${({ candidates }) => (candidates ? '58px' : '58px')};
  z-index: 5;

  &:before {
    content: '';
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    border-bottom: 11px solid #f1f1f1;
    position: absolute;
    top: -10px;
    left: ${({ candidates }) => (candidates ? '90px' : '49px')};
  }

  &:after {
    content: '';
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -9px;
    left: ${({ candidates }) => (candidates ? '90px' : '50px')};
  }

  ul {
    padding: 20px 0;
    margin: 0px;
    list-style-type: none;
    li {
      margin-bottom: 10px;
      display: block;
    }

    a {
      padding: 10px 20px;
      color: #333;
      font-weight: 500;
      font-size: 14px;
      display: block;
      text-decoration: none;

      &:hover {
        background: #f5f5f5;
        text-decoration: none;
      }
    }
    p {
      padding: 10px 20px;
      color: #333;
      font-weight: 500;
      font-size: 14px;
      display: block;

      &:hover {
        background: #f5f5f5;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid: 40px 1fr / 1fr;
  grid-row-gap: 5px;
  label:first-child {
    text-transform: uppercase;
    color: #666;
    font-weight: bold;
    font-size: 12px;
    display: grid;
    align-items: end;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form``;

export const BackContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  font-size: 14px;
  grid-column-gap: 12px;
  color: #666;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`;

export const RightContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ typeButton }) =>
    typeButton ? 'auto' : 'minmax(100px, 320px)'};
  justify-content: end;
`;

export const InputWithIcon = styled.div`
  display: grid;
  height: 50px;
  background: #fff;
  align-items: center;
  padding: 0 17px;
  justify-content: start;
  grid-column-gap: 17px;
  grid-template-columns: auto 1fr;
  border-radius: 2px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Display as one column on smaller screens */
  }

  @media (min-width: 769px) {
    grid-template-columns: auto 1fr; /* Display as two columns on larger screens */
  }
  input {
    border: none;
    font-size: 14px;
    color: #999;
    &:hover,
    &:focus {
      border: none;
      outline-width: 0;
    }
  }
`;

export const TableRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${({ withCheckbox, rowCount = 5 }) =>
    withCheckbox
      ? rowCount
        ? '30px repeat(' + rowCount + ', 1fr) 30px'
        : '30px repeat(3, 1fr) 30px'
      : 'repeat(' + rowCount + ', 1fr)'};
  justify-items: start;
  word-wrap: break-word;
  word-break: break-all;
  grid-column-gap: 10px;
  align-items: center;
  text-transform: ${({ header }) => (header ? 'uppercase' : null)};
  font-size: 14px;
  color: #666;
  font-weight: 500;
  padding: 26px 20px;
  border-bottom: 1px solid #f2f2f2;
  background: ${({ header }) => (header ? '#fcfcfc' : 'none')};
  &:first-child {
    display: grid;
  }

  a {
    color: #408996;
    text-decoration: underline;
  }

  img {
    cursor: pointer;
  }
`;

export const PaginationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  div {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: auto minmax(auto, 70px);
    &:first-child {
      grid-template-columns: repeat(5, 35px);
    }

    &:last-child {
      justify-items: end;
      span {
        color: #454d59;
        position: relative;
      }
    }

    button {
      display: inline-block;
      width: 32px;
      height: 32px;
      border: 1px solid #b0bac9;
      background: #fff;
      color: #408996;
      font-size: 14px;
    }

    span {
      font-size: 14px;
      color: #b0bac9;

      &:nth-child(2n) {
        color: #333;
      }
    }
  }
`;

export const ArrrowDown = styled.img`
  position: absolute;
  right: 10px;
  top: 15px;
`;

export const ClientName = styled.div`
  display: flex;
  align-items: center;
`;

export const PageSelector = styled.select`
  border: 1px solid #b0bac9;
  appearance: none;
  padding: 10px 25px 10px 18px;
  position: relative;
`;

export const CardLink = styled.a`
  text-decoration: 'none';
  color: '#000';
`;

export const ClearButton = styled.button`
  background-color: transparent;
  color: #408996;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;
export const GreenButton = styled.button`
  background-color: #4d909d;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
