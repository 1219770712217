import gql from 'graphql-tag';

export const UPDATE_CANDIDATE = gql`
  mutation updateCandidate(
    $email: String!
    $firstname: String!
    $lastname: String!
    $phone: String!
    $address: String!
    $candidateId: UUID!
    $client: UUID!
    $batch: UUID!
  ) {
    updateCandidate(
      candidateDetails: {
        email: $email
        firstname: $firstname
        lastname: $lastname
        phone: $phone
        address: $address
        client: $client
        batch: $batch
      }
      candidateId: $candidateId
    ) {
      ok
      errors {
        messages
      }
    }
  }
`;

export const DELETE_CANDIDATE = gql`
  mutation deleteCandidate($id: UUID!) {
    deleteCandidate(id: $id) {
      ok
    }
  }
`;

export const CREATE_CANDIDATE = gql`
  mutation createCandidate(
    $firstname: String!
    $lastname: String!
    $email: String!
    $address: String!
    $phone: String!
    $client: UUID!
    $batch: UUID!
  ) {
    createCandidate(
      candidateDetails: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        address: $address
        phone: $phone
        client: $client
        batch: $batch
      }
    ) {
      ok
      errors {
        messages
      }
      candidate {
        firstname
        lastname
        email
        phone
      }
    }
  }
`;

export const CREATE_BULK_CANDIDATES = gql`
  mutation uploadCandidates($id: UUID!, $file: Upload!) {
    createCandidates(id: $id, input: { upload: $file }) {
      ok
      errors {
        messages
      }
      success
    }
  }
`;

export const CREATE_REFERENCE = gql`
  mutation createReferenceRequest(
    $verificationRequest: UUID!
    $id: UUID
    $name: String!
    $email: String!
    $officialEmail: String!
    $phone: String!
    $address: String!
    $officeAddress: String!
  ) {
    createReferenceRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        name: $name
        email: $email
        officialEmail: $officialEmail
        phone: $phone
        address: $address
        officeAddress: $officeAddress
      }
    ) {
      ok
      errors {
        messages
      }
      reference {
        id
        isDone
        email
        phone
        name
        address
        officeAddress
        officialEmail
      }
    }
  }
`;

export const CREATE_GUARANTOR = gql`
  mutation createGuarantorRequest(
    $verificationRequest: UUID!
    $id: UUID
    $name: String!
    $email: String!
    $phone: String!
    $address: String!
    $organization: String!
    $role: String!
    $officialAddress: String!
    $officialEmail: String!
    $officialPhone: String!
    $confirmationDoc: Upload
    $upload: Upload
  ) {
    createGuarantorRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        name: $name
        email: $email
        phone: $phone
        address: $address
        organization: $organization
        role: $role
        officialAddress: $officialAddress
        officialEmail: $officialEmail
        officialPhone: $officialPhone
        confirmationDoc: $confirmationDoc
        upload: $upload
      }
    ) {
      ok
      errors {
        messages
      }
      guarantor {
        id
        isDone
        email
        phone
        name
        address
        organization
        role
        officialEmail
        officialAddress
        officialPhone
        upload
      }
    }
  }
`;

export const CREATE_PROFESSIONAL = gql`
  mutation createProfessionalRequest(
    $verificationRequest: UUID!
    $id: UUID
    $awardBody: String!
    $certificateName: String!
    $certificateNumber: String!
    $dateAwarded: Date!
    $consent: Upload
    $confirmationDoc: Upload
    $upload: Upload
  ) {
    createProfessionalRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        awardBody: $awardBody
        certificateName: $certificateName
        certificateNumber: $certificateNumber
        dateAwarded: $dateAwarded
        consent: $consent
        confirmationDoc: $confirmationDoc
        upload: $upload
      }
    ) {
      ok
      errors {
        messages
      }
      professional {
        id
        isDone
        status
        consent
        confirmationDoc
        awardBody
        certificateName
        certificateNumber
        dateAwarded
        upload
      }
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddressRequest(
    $id: UUID
    $verificationRequest: UUID!
    $address: String!
    $nearestBusStop: String!
    $nearestLandmark: String!
    $city: String!
    $localGovernment: String!
    $state: String!
    $contactPerson: String!
    $phoneNumber: String!
  ) {
    createAddressRequest(
      input: {
        id: $id
        verificationRequest: $verificationRequest
        address: $address
        nearestBusStop: $nearestBusStop
        nearestLandmark: $nearestLandmark
        city: $city
        localGovernment: $localGovernment
        state: $state
        contactPerson: $contactPerson
        phoneNumber: $phoneNumber
      }
    ) {
      ok
      errors {
        messages
      }
      address {
        id
        isDone
        status
      }
    }
  }
`;
export const CREATE_CREDIT = gql`
  mutation createCreditRequest(
    $verificationRequest: UUID!
    $id: UUID
    $bvn: String!
    $phoneNumber: String!
    $dob: Date!
    $meansOfIdentification: String
    $idNumber: String
    $idFront: Upload
    $idBack: Upload
  ) {
    createCreditRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        bvn: $bvn
        phoneNumber: $phoneNumber
        dob: $dob
        meansOfIdentification: $meansOfIdentification
        idNumber: $idNumber
        idFront: $idFront
        idBack: $idBack
      }
    ) {
      ok
      errors {
        messages
      }
      credit {
        id
        isDone
        status
      }
    }
  }
`;

export const CREATE_CRIMINAL = gql`
  mutation createCriminalRequest(
    $verificationRequest: UUID!
    $id: UUID
    $fingerprintCapturingDate: Date!
    $fingerprintCapturingVenue: String!
    $race: String!
    $passport: Upload
  ) {
    createCriminalRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        race: $race
        fingerprintCapturingDate: $fingerprintCapturingDate
        fingerprintCapturingVenue: $fingerprintCapturingVenue
        passport: $passport
      }
    ) {
      ok
      errors {
        messages
      }
      criminal {
        id
        isDone
        status
        passport
      }
    }
  }
`;

export const CREATE_NYSC = gql`
  mutation createNyscRequest(
    $verificationRequest: UUID!
    $id: UUID
    $callNumber: String!
    $certificateNumber: String!
    $dateStarted: Date!
    $dateCompleted: Date!
    $certificateType: String!
    $groundsForExemption: String
    $confirmationDoc: Upload
    $upload: Upload
  ) {
    createNyscRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        callNumber: $callNumber
        certificateNumber: $certificateNumber
        dateStarted: $dateStarted
        dateCompleted: $dateCompleted
        certificateType: $certificateType
        groundsForExemption: $groundsForExemption
        confirmationDoc: $confirmationDoc
        upload: $upload
      }
    ) {
      ok
      errors {
        messages
      }
      nysc {
        id
        isDone
        status
        confirmationDoc
        createdAt
        dateCompleted
        dateStarted
        groundsForExemption
      }
    }
  }
`;

export const CREATE_EMPLOYERS = gql`
  mutation createEmploymentRequest(
    $verificationRequest: UUID!
    $id: UUID
    $employerName: String!
    $contactPerson: String!
    $contactPersonRole: String!
    $email: String!
    $address: String!
    $phone: String!
    $consent: Upload
  ) {
    createEmploymentRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        employerName: $employerName
        contactPerson: $contactPerson
        contactPersonRole: $contactPersonRole
        email: $email
        address: $address
        phone: $phone
        consent: $consent
      }
    ) {
      ok
      errors {
        messages
      }
      employment {
        id
        isDone
        status
        consent
        employerName
        contactPerson
        contactPersonRole
        email
        address
        phone
      }
    }
  }
`;

export const ADD_ACADEMIC_VERIFICATION = gql`
  mutation academicRequest(
    $id: UUID
    $vId: UUID!
    $name: String!
    $qualification: String!
    $course: String!
    $grade: String!
    $location: String!
    $yearStarted: Date!
    $yearCompleted: Date!
    $cert: Upload
    $consent: Upload
  ) {
    createAcademicRequest(
      input: {
        verificationRequest: $vId
        id: $id
        nameOfInstitution: $name
        qualification: $qualification
        courseOfStudy: $course
        grade: $grade
        location: $location
        yearStarted: $yearStarted
        yearCompleted: $yearCompleted
        upload: $cert
        consent: $consent
      }
    ) {
      ok
      errors {
        messages
      }
      academic {
        id
        nameOfInstitution
        qualification
        courseOfStudy
        grade
        location
        yearStarted
        yearCompleted
        upload
      }
    }
  }
`;

export const CREATE_OLEVEL = gql`
  mutation createOlevelRequest(
    $verificationRequest: UUID!
    $id: UUID
    $type: String!
    $certificate: Upload
    $year: Date!
    $examName: String!
    $examNumber: String!
  ) {
    createOlevelRequest(
      input: {
        verificationRequest: $verificationRequest
        id: $id
        type: $type
        certificate: $certificate
        year: $year
        examName: $examName
        examNumber: $examNumber
      }
    ) {
      ok
      errors {
        messages
      }
      olevel {
        id
        certificate
        examName
        examNumber
        year
        type
      }
    }
  }
`;
export const CREATE_SOCIAL_MEDIA = gql`
  mutation createSocialMediaRequest(
    $verificationRequest: UUID!
    $id: UUID
    $handle: String!
    $url: String!
  ) {
    createSocialMediaRequest(
      input: {
        verificationRequest: $verificationRequest
        handle: $handle
        id: $id
        url: $url
      }
    ) {
      ok
      errors {
        messages
      }
      socialMedia {
        id
        handle
        url
      }
    }
  }
`;
export const ADD_CONSENT_FORM = gql`
  mutation CreateConsent(
    $verificationRequest: UUID!
    $file: Upload!
    $passportPhoto: Upload!
    $token: String
  ) {
    createConsent(
      input: {
        verificationRequest: $verificationRequest
        file: $file
        passportPhoto: $passportPhoto
        token: $token
      }
    ) {
      ok
      errors {
        messages
      }
    }
  }
`;
export const ADD_VERIFY_CANDIDATE = gql`
  mutation verifyCandidate($email: String!, $token: String!) {
    verifyCandidate(email: $email, token: $token) {
      ok
      errors {
        messages
      }
    }
  }
`;
