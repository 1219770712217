import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Form, InputContainer } from '../../Admin/Clients/styles';
import {
  AcademicForm,
  AcademicContainer,
  ConsentSection,
  RoundedButton,
  InfoCard
} from '../styles';

import { svgs } from 'assets';
import FormUploader from 'components/Uploader';
import Modal from 'components/Modal';
import ActionView from 'components/Modal/ActionView';
import Button from 'components/common/Button';
import { Error } from '../../Signin/styles';
import { ADD_CONSENT_FORM } from 'graphql/resolvers/admin/candidates/mutations';
import { renameFileUpload } from 'utils/renameUploadUtils';
import Container from 'components/common/Container';

const Submit = ({ file, fileName, token }) => {
  const [show, setShow] = useState(false);
  const checkFile = (file) => {
    if (file) {
      return file;
    } else {
      return null;
    }
  };
  const [submitted, setSubmitted] = useState(false);
  const [showConsentError, setShowConsentError] = useState(false);
  const [consentForm, setConsentForm] = useState(checkFile(null));
  const [showPassportError, setShowPassportError] = useState(false);
  const [passportPhoto, setPassportPhoto] = useState(checkFile(null));

  const [createConsent] = useMutation(ADD_CONSENT_FORM);

  useEffect(() => {
    if (file) {
      setSubmitted(true);
    }
  }, [file]);

  const handleConsentForm = (files) => {
    const modifiedFile = files[0];
    // const modifiedFile = renameFileUpload(file, fileName || 'Authorization_Letter');
    setConsentForm(modifiedFile);
    setShowConsentError(false);
  };
  const handlePassportPhoto = (files) => {
    const modifiedFile = files[0];
    // const modifiedFile = renameFileUpload(file, fileName || 'Authorization_Letter');
    setPassportPhoto(modifiedFile);
    setShowPassportError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowConsentError(false);
    setShow(!show);
    if (!consentForm) {
      setShowConsentError(true);
    }
    if (!passportPhoto) {
      setShowPassportError(true);
    }

    if (consentForm && passportPhoto) {
      try {
        const response = await createConsent({
          variables: {
            verificationRequest: localStorage.getItem('verificationId'),
            file: consentForm,
            passportPhoto: passportPhoto,
            token
          }
        });
        const { ok, errors } = response.data.createConsent;
        if (ok) {
          setSubmitted(true);
        } else {
          setSubmitted(false);
        }
      } catch (err) {
        setSubmitted(false);
      }
    }
  };

  return (
    <Container>
      {submitted ? (
        <div>
          <Form>
            <div style={{ padding: '30px' }}>
              <InfoCard>
                <p>
                  Thanks for taking your time to <br />
                  provide the required information.
                </p>

                <p>
                  Your employer would be updated on the status of your background check.
                </p>
              </InfoCard>
            </div>
          </Form>
        </div>
      ) : (
        <AcademicContainer>
          <AcademicForm>
            <Form onSubmit={handleSubmit}>
              <InputContainer>
                <label htmlFor="">Upload Passport Photograph</label>
                <FormUploader
                  form
                  className={showPassportError ? 'Invalid' : ''}
                  accept={[
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'image/png',
                    'image/jpg',
                    'image/jpeg'
                  ]}
                  handleUpload={handlePassportPhoto}
                  text="Upload Photo"
                  file={passportPhoto}
                />
                {showPassportError ? (
                  <Error>Please upload Passport Photograph</Error>
                ) : null}
              </InputContainer>

              <InputContainer>
                <label htmlFor="">Upload Letter Of Authorization</label>
                <FormUploader
                  form
                  className={showConsentError ? 'Invalid' : ''}
                  accept={[
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ]}
                  handleUpload={handleConsentForm}
                  text="Upload File"
                  file={consentForm}
                />
                {showConsentError ? (
                  <Error>Please upload signed Letter of Authorization</Error>
                ) : null}
              </InputContainer>

              <Button type="button" onClick={() => setShow(true)}>
                Submit
              </Button>
            </Form>
          </AcademicForm>
          <ConsentSection>
            <span>Letter Of Authorization</span>
            <p>
              Please, download, print, sign and reupload the consent form using the
              provided field.
            </p>
            <p>
              <a
                href={process.env.REACT_APP_AUTHORIZATION_LETTER}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RoundedButton>
                  Letter Of Authorization
                  <img src={svgs.DOWNLOAD_ICON} alt="download" />
                </RoundedButton>
              </a>
            </p>
          </ConsentSection>
        </AcademicContainer>
      )}
      {show && (
        <Modal show={show} setShowModal={setShow} title={'Submit Request'}>
          <ActionView
            title={
              'Are you sure you want to submit. Once submitted, the form can not be edited '
            }
            icon={svgs.SUCCESS_ICON}
            actionText="Submit Request"
            actionLoader={false}
            actionHandler={handleSubmit}
          />
        </Modal>
      )}
    </Container>
  );
};

export default Submit;
