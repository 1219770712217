import styled from 'styled-components';

export const MainContainer = styled.div`
  display: grid;
  padding: 0 15%;
  grid-template-columns: 1fr;
  background: ${({ whiteBg }) => (whiteBg ? '#fff' : 'none')};
  margin-bottom: 30px;

  @media (max-width: 600px) {
    display: block;
    padding: 2px;
  }
`;
