import React, { useState, useEffect, useCallback } from 'react';
import RequestTabs from 'components/RequestTabs';
import { useQuery } from '@apollo/client';
import Academic from './Academic';
import Olevel from './Olevel';
import Reference from './Reference';
import Guarantor from './Guarantor';
import Nysc from './Nysc';
import Criminal from './Criminal';
import Credit from './Credit';
import SocialMedia from './SocialMedia';
import Professional from './Professional';
import Address from './Address';
import Submit from './Submit';
import { GET_VERIFICATOIN_LIST } from 'graphql/resolvers/admin/clients/queries';
import { GET_CONSENT_DOCS } from 'graphql/queries/candidates/queries';
import { GET_CANDIDATE_VERIFICATION_REQUEST } from 'graphql/resolvers/client/queries';
import Loader from 'components/common/Loader';
import { LoaderContainer } from 'components/FullPageLoader/loader';
import Employer from './Employer';

const mapResultToComponent = {
  Academic: Academic,
  Professional: Professional,
  Credit: Credit,
  Reference: Reference,
  Guarantor: Guarantor,
  Address: Address,
  Criminal: Criminal,
  NYSC: Nysc,
  Employment: Employer,
  OLevel: Olevel,
  SocialMedia: SocialMedia
};

const Candidate = ({ history, match: { params }, ...props }) => {
  let verfId;
  const [tabs, setTabs] = useState([]);
  const [availableTabs, setAvailableTabs] = useState([]);
  const { loading, data, errors } = useQuery(GET_VERIFICATOIN_LIST);
  const [response, setResponse] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const handleTabs = (tab) => setActiveTab(tab);
  const [newInfo, setNewinfo] = useState({});

  const { loading: loadindDocs, data: docsList, errors: docsError } = useQuery(
    GET_CONSENT_DOCS
  );

  const checker = availableTabs?.length;
  const incrementTab = useCallback(
    (index) => {
      if (!verfId) {
        index <= checker ? setActiveTab(index + 1) : setActiveTab(index);
      } else {
        index <= JSON.parse(localStorage.getItem('verificationTypes')).length
          ? setActiveTab(index + 1)
          : setActiveTab(index);
      }
    },
    [checker, verfId]
  );

  const decrementTab = (index) => {
    index > 0 ? setActiveTab(index - 1) : setActiveTab(index);
  };

  const { loading: loadingCandidates, data: candidatesData } = useQuery(
    GET_CANDIDATE_VERIFICATION_REQUEST,
    {
      variables: {
        id: params?.verifyId
      }
    }
  );

  const fileName = `${candidatesData?.verificationRequest?.candidate?.firstname}_${candidatesData?.verificationRequest?.candidate?.lastname}`;
  const savedSubmit = candidatesData?.verificationRequest?.consentRequest;

  const savedAcademic = candidatesData?.verificationRequest?.acadRequest?.length
    ? candidatesData?.verificationRequest?.acadRequest.map((acad) => ({
        id: acad.id,
        name: acad.nameOfInstitution,
        degree: acad.qualification,
        major: acad.courseOfStudy,
        grade: acad.grade,
        location: acad.location,
        yearStarted: new Date(acad.yearStarted).getFullYear().toString(),
        yearCompleted: new Date(acad.yearCompleted).getFullYear().toString(),
        cert: acad.upload
      }))
    : [];
  const savedAddress = candidatesData?.verificationRequest?.addressRequest?.length
    ? candidatesData?.verificationRequest?.addressRequest?.map((add) => ({
        id: add.id,
        address: add.address,
        contactPerson: add.contactPerson,
        phoneNumber: add.phoneNumber,
        busStop: add.nearestBusStop,
        landmark: add.nearestLandmark,
        city: add.city,
        localGovt: add.localGovernment,
        state: add.state
      }))
    : [];

  const identicationOptions = {
    NIN: 'NIN',
    DRIVERS_LICENCE: 'DRIVERS LICENCE',
    INTL_PASSPORT: ' INTL PASSPORT',
    PERMANENT_VOTERS_CARD: 'PERMANENT VOTERS CARD'
  };
  const savedCredit = candidatesData?.verificationRequest?.creditRequest?.length
    ? candidatesData?.verificationRequest?.creditRequest.map((credit) => ({
        id: credit.id,
        meansOfIdentification: identicationOptions[credit.meansOfIdentification],
        bvn: credit.bvn,
        phoneNumber: credit.phoneNumber,
        dob: credit.dob,
        idNumber: credit.idNumber,
        idFront: credit.idFront,
        idBack: credit.idBack
      }))
    : [];
  const savedCriminal = candidatesData?.verificationRequest?.criminalRequest?.length
    ? candidatesData?.verificationRequest?.criminalRequest.map((crime) => {
        const date = new Date(crime.fingerprintCapturingDate);
        const formattedDate = date.toISOString().slice(0, 10);
        return {
          id: crime.id,
          race: crime.race,
          date: formattedDate,
          venue: crime.fingerprintCapturingVenue,
          passport: crime.passport
        };
      })
    : [];
  const savedEmployment = candidatesData?.verificationRequest?.employeeRequest?.length
    ? candidatesData?.verificationRequest?.employeeRequest.map((emp) => ({
        id: emp.id,
        name: emp.employerName,
        contactPerson: emp.contactPerson,
        contactPersonRole: emp.contactPersonRole,
        email: emp.email,
        address: emp.address,
        phone: emp.phone
      }))
    : [];
  const savedGuarantor = candidatesData?.verificationRequest?.guarantorRequest?.length
    ? candidatesData?.verificationRequest?.guarantorRequest.map((gua) => ({
        id: gua.id,
        guarantorName: gua.name,
        email: gua.email,
        phone: gua.phone,
        address: gua.address,
        organization: gua.organization,
        role: gua.role,
        officialEmail: gua.officialEmail,
        officialPhone: gua.officialPhone,
        officialAddress: gua.officialEmail,
        upload: gua.upload
      }))
    : [];
  const certificateTypeOptions = {
    NYSC_DISCHARGE_CERTIFICATE: 'NYSC DISCHARGE CERTIFICATE',
    NYSC_EXEMPTION_CERTIFICATE: 'NYSC EXEMPTION CERTIFICATE',
    NYSC_EXCLUSION_CERTIFICATE: 'NYSC EXCLUSION CERTIFICATE'
  };

  const savedNYSC = candidatesData?.verificationRequest?.nyscRequest?.length
    ? candidatesData?.verificationRequest?.nyscRequest.map((nysc) => ({
        id: nysc.id,
        callNumber: nysc.callNumber,
        upload: nysc.upload,
        certificateNumber: nysc.certificateNumber,
        dateStarted: nysc.dateStarted,
        dateCompleted: nysc.dateCompleted,
        certificate_type: certificateTypeOptions[nysc.certificateType],
        exemption_grounds: nysc.groundsForExemption
      }))
    : [];
  const savedOLevel = candidatesData?.verificationRequest?.olevelRequest?.length
    ? candidatesData?.verificationRequest?.olevelRequest.map((level) => ({
        id: level.id,
        certificate: level.certificate,
        examName: level.examName,
        examNumber: level.examNumber,
        year: level.year,
        type: level.type
      }))
    : [];
  const savedProfessional = candidatesData?.verificationRequest?.profRequest?.length
    ? candidatesData?.verificationRequest?.profRequest.map((prof) => ({
        id: prof.id,
        awardingBody: prof.awardBody,
        certificateName: prof.certificateName,
        certificateNumber: prof.certificateNumber,
        dateAwarded: prof.dateAwarded,
        upload: prof.upload
      }))
    : [];
  const savedReference = candidatesData?.verificationRequest?.refRequest?.length
    ? candidatesData?.verificationRequest?.refRequest.map((ref) => ({
        id: ref.id,
        referenceName: ref.name,
        email: ref.email,
        officialEmail: ref.officialEmail,
        phone: ref.phone,
        address: ref.address,
        officeAddress: ref.officeAddress
      }))
    : [];

  const savedSocialMedia = candidatesData?.verificationRequest?.socialMediaRequest?.length
    ? candidatesData?.verificationRequest?.socialMediaRequest.map((social) => ({
        id: social.id,
        name: social.handle,
        link: social.url
      }))
    : [];

  const saveDetail = {
    Academic: savedAcademic,
    Address: savedAddress,
    Credit: savedCredit,
    Criminal: savedCriminal,
    Employment: savedEmployment,
    Guarantor: savedGuarantor,
    NYSC: savedNYSC,
    OLevel: savedOLevel,
    Professional: savedProfessional,
    Reference: savedReference,
    SocialMedia: savedSocialMedia
  };

  const displayDetail = {
    Academic: candidatesData?.verificationRequest?.acadRequest,
    Address: candidatesData?.verificationRequest?.addressRequest,
    Credit: candidatesData?.verificationRequest?.creditRequest,
    Criminal: candidatesData?.verificationRequest?.criminalRequest,
    Employment: candidatesData?.verificationRequest?.employeeRequest,
    Guarantor: candidatesData?.verificationRequest?.guarantorRequest,
    NYSC: candidatesData?.verificationRequest?.nyscRequest,
    OLevel: candidatesData?.verificationRequest?.olevelRequest,
    Professional: candidatesData?.verificationRequest?.profRequest,
    Reference: candidatesData?.verificationRequest?.refRequest,
    SocialMedia: candidatesData?.verificationRequest?.socialMediaRequest
  };

  const RenderComponent = useCallback(
    (component, props) => {
      let ComponentName = mapResultToComponent[component];
      const getDocUrl = (name) => {
        const consentDoc = docsList?.documentList?.results.filter((doc) => {
          return doc.name === `${name.toUpperCase()}_CONSENT`;
        });

        if (consentDoc) return consentDoc;
        else {
          return [];
        }
      };

      const firstArrayChecker = saveDetail[component];
      const secondArrayChecker = displayDetail[component]?.filter(
        (req) => req.status === 'PENDING'
      );

      return (
        <>
          {(firstArrayChecker?.length === 0 ||
            (firstArrayChecker?.length > 0 && secondArrayChecker?.length > 0)) && (
            <ComponentName
              nextTab={incrementTab}
              newInfo={newInfo}
              setNewinfo={setNewinfo}
              prevTab={decrementTab}
              index={activeTab}
              fileName={fileName}
              consentDoc={getDocUrl(component)}
              savedData={saveDetail[component]}
            />
          )}
        </>
      );
    },
    [activeTab, docsList, incrementTab, newInfo] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderSubmit = useCallback(
    (component, props) => {
      const closureComponent = { submit: Submit };
      let ComponentName = closureComponent[component];
      const closing = savedSubmit?.length ? savedSubmit : [];

      return (
        <ComponentName
          index={activeTab}
          file={closing.length ? closing[closing.length - 1].file : ''}
          fileName={fileName}
          token={params?.token}
        />
      );
    },
    [savedSubmit, activeTab, fileName, params]
  );

  useEffect(() => {
    if (!loading && !loadingCandidates) {
      setResponse(data?.verificationOptionList?.results);
      setAvailableTabs(candidatesData?.verificationRequest?.requestTypes);
      localStorage.setItem('verificationId', params?.verifyId);

      let newArray =
        response &&
        response.filter((tabItem) => {
          return availableTabs?.includes(tabItem.id) ? tabItem : null;
        });

      newArray = newArray?.filter((item) => {
        const firstCheck = saveDetail[item.name];
        const secondCheck = displayDetail[item.name]?.filter(
          (req) => req.status === 'PENDING'
        );

        return firstCheck?.length === 0 ||
          (firstCheck?.length > 0 && secondCheck?.length > 0)
          ? item
          : null;
      });

      newArray = newArray?.map((item) => {
        return {
          id: item.id,
          name: item.name === 'Employment' ? 'Previous Employer' : item.name,
          component: RenderComponent(item.name)
        };
      });

      newArray.push({
        id: 1,
        name: 'Submit',
        component: RenderSubmit('submit')
      });

      setTabs(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    candidatesData,
    loading,
    loadingCandidates,
    response,
    availableTabs,
    RenderComponent,
    RenderSubmit
  ]);

  return loadingCandidates && loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <RequestTabs
      tabs={tabs}
      activeTab={activeTab}
      tabNav={false}
      handleTabs={handleTabs}
      tabOnly
    />
  );
};

export default Candidate;
