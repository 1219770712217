import gql from 'graphql-tag';

export const typeDefs = gql`
  type AuthUser {
    id: ID
    email: String
    firstname: String
    lastname: String
    image: String
    phone: String
    roles: String
    companyRoles: [String]
    isAuthenticated: Boolean
  }
  type Mutation {
    setAuthUserMutation(user: AuthUser!, token: String!): AuthUser!
  }
  type Query {
    getAuthUserQuery: AuthUser!
  }
`;
