import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

import { CREATE_GUARANTOR } from 'graphql/resolvers/admin/candidates/mutations';
import FormUploader from 'components/Uploader';
import { InputContainer, Form } from '../../Admin/Clients/styles';
import { AcademicForm, FormFooter, AddSection } from '../styles';
import { Error } from 'pages/Signin/styles';
import { toastSuccess, toastError } from 'components/common/Toast';
import { guarantorValidationSchema } from 'helpers/validators';
import { renameFileUpload } from 'utils/renameUploadUtils';

import Container from 'components/common/Container';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import { svgs } from 'assets';

const Guarantor = ({
  nextTab,
  index,
  prevTab,
  newInfo,
  setNewinfo,
  savedData,
  fileName,
  ...props
}) => {
  const checkFile = (file) => {
    if (file) {
      return file;
    } else {
      return null;
    }
  };

  const [showLoader, setLoaderState] = useState(false);

  const [createGuarantorRequest] = useMutation(CREATE_GUARANTOR);

  const handleOnSubmit = async (values) => {
    const payload = [];
    setLoaderState(true);
    for (let i = 0; i < values.guarantorDetails.length; i++) {
      const response = createGuarantorRequest({
        variables: {
          verificationRequest: localStorage.getItem('verificationId'),
          id: values.guarantorDetails?.[i].id ? values.guarantorDetails?.[i].id : null,
          name: values.guarantorDetails?.[i].guarantorName,
          email: values.guarantorDetails?.[i].email,
          phone: values.guarantorDetails?.[i].phone,
          address: values.guarantorDetails?.[i].address,
          organization: values.guarantorDetails?.[i].organization,
          role: values.guarantorDetails?.[i].role,
          officialAddress: values.guarantorDetails?.[i].officialAddress,
          officialEmail: values.guarantorDetails?.[i].officialEmail,
          officialPhone: values.guarantorDetails?.[i].officialPhone,
          upload: values.guarantorDetails?.[i].upload
        }
      });
      payload.push(response);
    }
    const results = await Promise.allSettled(payload);

    const dataLoads = [];

    results.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        dataLoads.push(value.data.createGuarantorRequest.guarantor);
      }
    });

    const guarantorDetails = dataLoads?.length
      ? dataLoads.map((gua) => ({
          id: gua.id,
          guarantorName: gua.name,
          email: gua.email,
          phone: gua.phone,
          address: gua.address,
          organization: gua.organization,
          role: gua.role,
          officialEmail: gua.officialEmail,
          officialPhone: gua.officialPhone,
          officialAddress: gua.officialEmail,
          upload: gua.upload
        }))
      : [];

    setNewinfo((prev) => ({
      ...prev,
      guarantor: {
        guarantorDetails
      }
    }));

    if (dataLoads.length) {
      toastSuccess(svgs.SUCCESS_ICON, 'Guarantor Request Submitted Successfully');
      nextTab(index);
    } else {
      toastError(svgs.DELETE_ICON, 'An error has occured');
    }

    setLoaderState(false);
  };

  return (
    <Formik
      initialValues={{
        guarantorDetails:
          newInfo?.guarantor?.guarantorDetails.length || savedData.length
            ? newInfo?.guarantor?.guarantorDetails || savedData
            : [
                {
                  id: '',
                  guarantorName: '',
                  email: '',
                  phone: '',
                  address: '',
                  organization: '',
                  role: '',
                  officialEmail: '',
                  officialPhone: '',
                  officialAddress: '',
                  upload: ''
                },
                {
                  id: '',
                  guarantorName: '',
                  email: '',
                  phone: '',
                  address: '',
                  organization: '',
                  role: '',
                  officialEmail: '',
                  officialPhone: '',
                  officialAddress: '',
                  upload: ''
                }
              ]
      }}
      validationSchema={guarantorValidationSchema}
      onSubmit={handleOnSubmit}
      render={({
        values,
        errors,
        touched,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur
      }) => {
        return (
          <Container>
            <AcademicForm reference>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <FieldArray
                  name="guarantorDetails"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.guarantorDetails.length > 0 &&
                        values.guarantorDetails.map((guarantor, index) => (
                          <div key={index} style={{ padding: '30px' }}>
                            <InputContainer>
                              <label htmlFor="guarantorName">Name of Guarantor</label>
                              <input
                                id="guarantorName"
                                name={`guarantorDetails.${index}.guarantorName`}
                                type="text"
                                placeholder="Guarantor Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].guarantorName}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.guarantorName &&
                                  errors?.guarantorDetails?.[index]?.guarantorName
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.guarantorName &&
                              errors?.guarantorDetails?.[index]?.guarantorName ? (
                                <Error>
                                  {errors?.guarantorDetails?.[index]?.guarantorName}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="email">Email</label>
                              <input
                                type="text"
                                name={`guarantorDetails.${index}.email`}
                                placeholder="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].email}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.email &&
                                  errors?.guarantorDetails?.[index]?.email
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.email &&
                              errors?.guarantorDetails?.[index]?.email ? (
                                <Error>{errors?.guarantorDetails?.[index]?.email}</Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="phone">Phone Number</label>
                              <input
                                id="phone"
                                name={`guarantorDetails.${index}.phone`}
                                type="number"
                                placeholder="Phone Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].phone}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.phone &&
                                  errors?.guarantorDetails?.[index]?.phone
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.phone &&
                              errors?.guarantorDetails?.[index]?.phone ? (
                                <Error>{errors?.guarantorDetails?.[index]?.phone}</Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="Address">Address</label>
                              <input
                                id="address"
                                name={`guarantorDetails.${index}.address`}
                                type="text"
                                placeholder="Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].address}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.address &&
                                  errors?.guarantorDetails?.[index]?.address
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.address &&
                              errors?.guarantorDetails?.[index]?.address ? (
                                <Error>
                                  {errors?.guarantorDetails?.[index]?.address}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="organization">organization</label>
                              <input
                                id="organization"
                                name={`guarantorDetails.${index}.organization`}
                                type="text"
                                placeholder="Organization"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].organization}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.organization &&
                                  errors?.guarantorDetails?.[index]?.organization
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.organization &&
                              errors?.guarantorDetails?.[index]?.organization ? (
                                <Error>
                                  {errors?.guarantorDetails?.[index]?.organization}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="role">role</label>
                              <input
                                id="role"
                                name={`guarantorDetails.${index}.role`}
                                type="text"
                                placeholder="Role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].role}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.role &&
                                  errors?.guarantorDetails?.[index]?.role
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.role &&
                              errors?.guarantorDetails?.[index]?.role ? (
                                <Error>{errors?.guarantorDetails?.[index]?.role}</Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="officialEmail">official Email</label>
                              <input
                                id="officialEmail"
                                name={`guarantorDetails.${index}.officialEmail`}
                                type="text"
                                placeholder="Official Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].officialEmail}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.officialEmail &&
                                  errors?.guarantorDetails?.[index]?.officialEmail
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.officialEmail &&
                              errors?.guarantorDetails?.[index]?.officialEmail ? (
                                <Error>
                                  {errors?.guarantorDetails?.[index]?.officialEmail}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="officialPhone">official phone</label>
                              <input
                                id="officialPhone"
                                name={`guarantorDetails.${index}.officialPhone`}
                                type="number"
                                placeholder="Official Phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].officialPhone}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.officialPhone &&
                                  errors?.guarantorDetails?.[index]?.officialPhone
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.officialPhone &&
                              errors?.guarantorDetails?.[index]?.officialPhone ? (
                                <Error>
                                  {errors?.guarantorDetails?.[index]?.officialPhone}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="officialAddress">official Address</label>
                              <input
                                id="officialAddress"
                                name={`guarantorDetails.${index}.officialAddress`}
                                type="text"
                                placeholder="Official Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.guarantorDetails?.[index].officialAddress}
                                className={`InputElement ${
                                  touched?.guarantorDetails?.[index]?.officialAddress &&
                                  errors?.guarantorDetails?.[index]?.officialAddress
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.guarantorDetails?.[index]?.officialAddress &&
                              errors?.guarantorDetails?.[index]?.officialAddress ? (
                                <Error>
                                  {errors?.guarantorDetails?.[index]?.officialAddress}
                                </Error>
                              ) : null}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="">Upload guarantor form</label>
                              <FormUploader
                                form
                                accept={[
                                  'application/pdf',
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg'
                                ]}
                                className={
                                  touched?.guarantorDetails?.[index]?.upload &&
                                  errors?.guarantorDetails?.[index]?.upload
                                    ? 'Invalid'
                                    : ''
                                }
                                handleUpload={(files) => {
                                  const modifiedFile = files[0];
                                  // const modifiedFile = renameFileUpload(
                                  //   file,
                                  //   fileName || 'Guarantor_form'
                                  // );
                                  setFieldValue(
                                    `guarantorDetails.${index}.upload`,
                                    modifiedFile
                                  );
                                }}
                                text="Upload File"
                                file={values?.guarantorDetails?.[index]?.upload}
                              />
                              {touched?.guarantorDetails?.[index]?.upload &&
                              errors?.guarantorDetails?.[index]?.upload ? (
                                <Error>{errors?.guarantorDetails?.[index]?.upload}</Error>
                              ) : null}
                            </InputContainer>
                            <AddSection>
                              <AddSection>
                                {index > 1 && (
                                  <button
                                    className="text-red"
                                    bgColor="transparent"
                                    textColor="#000"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    REMOVE
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => {
                                    push({
                                      guarantorName: '',
                                      email: '',
                                      phone: '',
                                      address: '',
                                      organization: '',
                                      role: '',
                                      officialEmail: '',
                                      officialPhone: '',
                                      officialAddress: '',
                                      upload: ''
                                    });
                                  }}
                                >
                                  + Add Guarantor
                                </button>
                              </AddSection>
                            </AddSection>
                          </div>
                        ))}
                    </>
                  )}
                />
                <FormFooter>
                  <Button backbutton type="button" onClick={() => prevTab(index)}>
                    Back
                  </Button>
                  <Button type="submit" disabled={showLoader ? true : false}>
                    {showLoader ? (
                      <div style={{ textAlign: 'center' }}>
                        <Loader />
                      </div>
                    ) : (
                      'Next'
                    )}
                  </Button>
                </FormFooter>
              </Form>
            </AcademicForm>
          </Container>
        );
      }}
    />
  );
};

export default Guarantor;
