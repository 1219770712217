/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import Header from 'components/Header';
import Restrict from 'components/common/Restrict';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { useAdminNavId } from 'context/adminNavIdContext';
import { MainContainer } from './styles';
import { Dropdown, DOWN_ICON } from '../Header/styles';

const Navbar = ({ children }) => {
  const [display, setDisplay] = useState(false);
  const { user } = useAuth();
  const { adminNavId } = useAdminNavId();

  // /admin_client/requests/:clientId
  const ref = useRef();
  const toggleDropdown = () => {
    setDisplay(!display);
  };

  return (
    <MainContainer>
      <Header>
        <Restrict role={user?.roles[0]} action={['admin-client:view']}>
          <li>
            <NavLink activeClassName="activeLink" to={'/admin/clients'}>
              Client
            </NavLink>
          </li>
        </Restrict>
        <Restrict role={user?.roles[0]} action={['client-candidates:view']}>
          <li>
            <NavLink
              activeClassName="activeLink"
              to={'/client/candidates?requestSent=no'}
            >
              Candidates
            </NavLink>
          </li>
        </Restrict>
        <Restrict role={user?.roles[0]} action={['client-requests:view']}>
          <li>
            <NavLink activeClassName="activeLink" to={'/client/requests'}>
              Requests
            </NavLink>
          </li>
        </Restrict>
        {adminNavId && (
          <Restrict role={user?.roles[0]} action={['requests:view']}>
            <li>
              <NavLink
                activeClassName="activeLink"
                to={`/admin_client/requests/${adminNavId}`}
              >
                Requests
              </NavLink>
            </li>
          </Restrict>
        )}
        <Restrict role={user?.roles[0]} action={['report:view']}>
          <li>
            <NavLink activeClassName="activeLink" to={'/admin/documents'}>
              Documents
            </NavLink>
          </li>
        </Restrict>
        <Restrict role={user?.roles[0]} action={['report:view']}>
          <li>
            <NavLink activeClassName="activeLink" to={'/admin/reports'}>
              Reports
            </NavLink>
          </li>
        </Restrict>
        <Restrict role={user?.roles[0]} action={['bulk-academic:view']}>
          <Dropdown view="nav">
            <li>
              <NavLink to={'#'} onClick={toggleDropdown}>
                Bulk Emails &nbsp;
                <DOWN_ICON display={display} />
              </NavLink>
            </li>
            <div
              ref={ref}
              className={`dropdown-content ${display ? 'show' : ''}`}
              onBlur={toggleDropdown}
              onFocus={toggleDropdown}
            >
              <Restrict role={user?.roles[0]} action={['bulk-academic:view']}>
                <NavLink to="/admin/bulk/academic" onClick={toggleDropdown}>
                  Academic
                </NavLink>
              </Restrict>
            </div>
          </Dropdown>
        </Restrict>
      </Header>
      {children}
    </MainContainer>
  );
};

export default Navbar;
