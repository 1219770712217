import moment from 'moment';

const dateFormatter = (date) => {
  if (date) {
    return new Date(date).toISOString().split('T')[0];
  } else {
    return new Date().toISOString().split('T')[0];
  }
};

export const formatDate = (date, format = 'D MMM YYYY') => {
  return moment(new Date(date)).format(format);
};

export default dateFormatter;
