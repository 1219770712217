import React, { useState, Fragment } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from '../../components/common/Button';
import { svgs } from '../../assets';
import { signInValidationSchema } from '../../helpers/validators';
import { useAuth } from '../../context/authContext';
import Loader from '../../components/common/Loader';
import FormCard from 'components/common/FormCard';
import AuthBackground from 'components/common/AuthBackground';

import {
  FormCardTitle,
  Form,
  Message,
  Error,
  InputContainer,
  Password,
  ForgotPasswordLink,
  Warning,
  Close,
  HideEye,
  Eye
} from './styles';
import { Redirect } from 'react-router-dom';
import { availableRoles } from 'config/constants';

const Signin = ({ history }) => {
  let { setAuthUser, loginUser, loginErrors, loading, user } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRedirect = () => {
    let path;
    switch (user?.roles[0]) {
      case availableRoles.ADMIN:
        path = '/admin/clients';
        break;
      case availableRoles.CLIENT:
        path = '/client/candidates?requestSent=no';
        break;
      case availableRoles.CANDIDATE:
        path = '/candidates';
        break;

      default:
        break;
    }
    return <Redirect to={path} />;
  };

  const passwordIcon = showPassword ? <Eye /> : <HideEye />;

  const handleSubmit = async (values) => {
    try {
      const { data } = await loginUser({ variables: values });

      setAuthUser({
        variables: {
          user: data.login.user,
          token: data.login.token
        }
      });
    } catch (err) {
      console.log(err, 'login errrr');
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      email: ''
    },
    validationSchema: Yup.object({
      ...signInValidationSchema
    }),
    onSubmit: handleSubmit
  });

  return (
    <Fragment>
      {user?.isAuthenticated ? (
        handleRedirect()
      ) : (
        <AuthBackground>
          <FormCard type="login">
            <svgs.LOGO className="logo" />
            <FormCardTitle>
              <h3>Welcome back</h3>
              <p>Please log in to continue</p>
            </FormCardTitle>
            {loading ? (
              <Loader fullpage={true} small={true}></Loader>
            ) : loginErrors && loginErrors.graphQLErrors[0] ? (
              <Message>
                <Warning />
                {loginErrors.graphQLErrors[0].message}
                <Close />
              </Message>
            ) : (
              ''
            )}
            <Form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`InputElement ${
                    formik.touched.email && formik.errors.email ? 'Invalid' : ''
                  }`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Error>
                    <Warning />
                    {formik.errors.email}
                  </Error>
                ) : null}
              </InputContainer>
              <InputContainer>
                <label htmlFor="password">password</label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`InputElement ${
                    formik.touched.password && formik.errors.password ? 'Invalid' : ''
                  }`}
                />
                <Password onClick={togglePassword}>{passwordIcon}</Password>
                {formik.touched.password && formik.errors.password ? (
                  <Error>
                    <Warning />
                    {formik.errors.password}
                  </Error>
                ) : null}
              </InputContainer>
              <span></span>
              <Button type="submit">Log In</Button>
            </Form>
            <ForgotPasswordLink href="/forgot-password">
              Forgot your password?
            </ForgotPasswordLink>
          </FormCard>
        </AuthBackground>
      )}
    </Fragment>
  );
};

export default Signin;
