import React, { Suspense } from 'react';
import Routes from 'routes';
import Loader from 'components/common/Loader';
import { useAuth } from 'context/authContext';
import { ToastContainer } from 'react-toastify';

const AuthenticatedApp = () => {
  const { user } = useAuth();
  return (
    <>
      <ToastContainer />

      <Suspense fallback={<Loader fullPage />}>
        {user?.isAuthenticated}
        <Routes />
      </Suspense>
    </>
  );
};

export default AuthenticatedApp;
