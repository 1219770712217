import { availableRoles } from 'config/constants';

export const accessRules = {
  [availableRoles.ADMIN]: {
    permissions: [
      'dashboard:view',
      'request:create',
      'request:edit',
      'manage-client:edit',
      'admin-client:view',
      'client-users:view',
      'request:view',
      'admin-settings:view',
      'report:view',
      'requests:view',
      'single-request:view',
      'adnin-setting:view',
      'clients:view',
      'bulk-academic:view',
      'initiate-request',
      'candidates-of-client:view',
      'admin-commence:view'
    ]
  },
  [availableRoles.CANDIDATE]: {
    permissions: ['profile:view', 'dashboard:view', 'candidates:view']
  },
  [availableRoles.CLIENT]: {
    permissions: [
      'dashboard:view',
      'client-candidates:view',
      'report:view',
      'verification:view',
      'client-requests:view',
      'single-request:view',
      'initiate-request',
      'candidates-of-client:view'
    ]
  }
};
