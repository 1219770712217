import styled from 'styled-components';

export const InfoCard = styled.div`
  text-align: center;
  p {
    line-height: 32px;
    font-weight: 500;
  }
  p:first-child {
    font-size: 21px;
    margin: 20px 0px;
  }
  p:last-child {
    font-size: 16px;
    color: #666666;
  }
`;

export const AcademicContainer = styled.div`
  display: grid;
  column-gap: 30px;
  grid-template-columns: minmax(300px, 80%) minmax(400px, 20%);
  align-items: start;

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const AcademicForm = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: #fff;
  padding: ${({ reference, professional }) =>
    reference ? '5px' : professional ? '10px' : '30px'};
  width: ${({ reference, professional }) =>
    reference ? '500px' : professional ? '70vw' : null};
  @media (max-width: 600px) {
    width: auto;
    padding: none;
  }
`;

export const ConsentSection = styled.div`
  display: grid;
  background: #fff;
  grid-template-rows: auto 1fr;
  span {
    font-size: 16px;
    padding: 30px 30px 0;
    font-weight: 500;
  }
  p {
    padding: 30px;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }
`;
export const AddSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin: 10px;
    padding: 15px;
    color: #0052cc;
    border-radius: 4px;
    background: white;
    text-align: center;
    // margin: 0 10px;
    width: 100%;
    text-decoration: none;
  }
`;

export const RoundedButton = styled.button`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  border: 1px solid rgba(64, 137, 150, 0.2);
  box-sizing: border-box;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background: none;
  cursor: pointer;
  color: #408996;
  font-size: 14px;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
`;

export const FormFooter = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  padding: 20px;
  background: #fcfcfc;
  width: 100%;
`;

export const UploadBar = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  column-gap: 10px;
  border: 1px solid rgba(64, 137, 150, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #408996;
    margin: 0 5px;
  }
`;
