import styled from 'styled-components';

export const LoginLink = styled.a`
  font-size: 1.4rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  margin-top: 1rem;
  justify-self: center;
  align-self: center;
  text-align: center;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    color: #34b991;
  }
`;

export const FormCardTitle = styled.div`
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 1.8rem;

    margin-bottom: 0.625rem;
  }

  p {
    font-weight: 200;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;

  span {
    display: block;
    margin-bottom: 1.5rem;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 18px 0;
  position: relative;

  label {
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .InputElement {
    outline: none;
    border: 0.5px solid #999999;
    background-color: #fff;
    font-size: 1.5rem;
    padding: 0.375rem 1rem;
    display: block;
    width: 100%;
    height: 3.3rem;
    border-radius: 2px;
    color: #333333;
    font-weight: 600;
    font-size: 1.1rem;

    &:focus {
      outline: none;
      border: 2px solid #34b991;
    }
  }

  .Invalid {
    border: 2px solid red;
    background: rgba(244, 67, 54, 0.1);

    &:focus {
      outline: none;
      border: 2px solid red;
    }
  }
`;

export const Error = styled.div`
  color: red;
  font-size: 1.2rem;
  margin-top: 0.7rem;
  position: absolute;
`;

export const Message = styled.div`
  width: 100%;
  height: 3rem;
  padding: 1rem;
  display: block;
  background-color: rgba(205, 49, 10, 0.1);
  border-radius: 2px;
  font-size: 1rem;
  color: #cd310a;
  border-left: 0.3rem solid #cd310a;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Success = styled.div`
  width: 100%;
  height: 3rem;
  padding: 1rem;
  display: block;
  background-color: rgba(0, 255, 51, 0.2);
  border-radius: 2px;
  font-size: 1rem;
  color: #00ff33;
  border-left: 0.3rem solid #00ff33;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;
