import styled from 'styled-components';

export const Section = styled.div`
  padding: ${({ form }) => (form ? '0' : '30px')};
  width: inherit;
`;

export const Uploader = styled.div`
  height: ${({ form }) => (form ? 'auto' : '300px')};
  display: grid;

  grid-auto-flow: row;
  grid-template-columns: ${({ form }) => (form ? 'auto 1fr' : '1fr')};
  justify-items: ${({ form }) => (form ? 'start' : 'center')};
  column-gap: ${({ form }) => (form ? '10px' : '0')};
  align-items: ${({ form }) => (form ? 'start' : 'center')};
  align-content: center;
  background: #fafafa;
  border: 1px dashed #eaeaea;
  box-sizing: border-box;
  padding: ${({ form }) => (form ? '20px' : '0')};
  p {
    margin: ${({ form }) => (form ? '5px 0' : '10px 0')};
    font-size: 14px;
    font-weight: 500;
  }

  label {
    margin: 10px 0;
    font-size: 12px;
    color: #5e6c84;
    span {
      color: #408996;
    }
  }
  @media (max-width: 600px) {
    display: block;
    padding: 2px;
  }
`;

export const UploadButtonContainer = styled.div`
  padding: 30px 0;
`;
