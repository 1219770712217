import {accessRules} from 'constants/accessRules';
import { checkPermissions } from 'helpers/checkPermissions';


/**
 * This Component returns a new 
 * component based roles
 * 
 * @returns component
 */
const Restrict = ({fallback, children, role, action}) =>
  checkPermissions(accessRules, role, action)
    ? children
    : fallback();

Restrict.defaultProps = {
  children: () => null,
  fallback: () => null
};

export default Restrict;
