import React from 'react';
import Button from '../../components/common/Button';
import AuthBackground from 'components/common/AuthBackground';
import FormCard from 'components/common/FormCard';
import { svgs } from 'assets';
import { FormCardTitle } from '../SetPassword/setPassword';

const SuccessPage = ({ history }) => {
  const { isValid, message, subMessage, email, resend } = history.location.state;
  if (!isValid) return history.push('/signin');

  const handleButton = () => history.push('/signin');
  return (
    <AuthBackground>
      <FormCard type="reset">
        <svgs.LOGO className="logo" />
        <FormCardTitle>
          <h3>{message}</h3>
          <p>{subMessage ?? 'You may now log in to your account'}</p>
          <p className="email">{email ?? ''}</p>
        </FormCardTitle>
        {!resend ? (
          <Button auto onClick={handleButton}>
            Proceed to login
          </Button>
        ) : (
          ''
        )}
      </FormCard>
    </AuthBackground>
  );
};

export default SuccessPage;
