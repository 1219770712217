import React from 'react';

import { ButtonContainer } from './styles';

const Button = ({ children, valid, ...props }) => {
  return <ButtonContainer {...props}>{children}</ButtonContainer>;
};

export const CancelButton = ({ children, valid, ...props }) => {
  return <ButtonContainer {...props}>{children}</ButtonContainer>;
};

export default Button;
