import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { forgotPasswordValidationSchema } from '../../helpers/validators';
import Button from '../../components/common/Button';
import AuthBackground from 'components/common/AuthBackground';
import { FORGOT_PASSWORD } from 'graphql/resolvers/auth/mutations';
import FormCard from 'components/common/FormCard';
import { svgs } from 'assets';
import {
  LoginLink,
  FormCardTitle,
  Form,
  InputContainer,
  Error,
  Message,
  Success
} from './forgotPassword';
import Loader from 'components/common/Loader';
import { Warning, Close } from 'pages/Signin/styles';

const ForgotPassword = ({ history }) => {
  const [forgotPassword, { data, error, loading }] = useMutation(FORGOT_PASSWORD);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      ...forgotPasswordValidationSchema
    }),
    onSubmit: async (values) => {
      try {
        await forgotPassword({ variables: values });
        history.push('/success', {
          isValid: true,
          message: 'Check your email',
          subMessage: 'Click the reset link we sent to',
          email: values?.email,
          resend: true
        });
      } catch (err) {
        console.log(err);
      }
    }
  });

  return (
    <AuthBackground>
      <FormCard type="reset">
        <svgs.LOGO className="logo" />
        <FormCardTitle>
          <h3>Forgot Password</h3>
          <p>
            Enter the email address linked to your account
            <br />
            and we&apos;ll send you a reset link
          </p>
        </FormCardTitle>
        {loading ? (
          <Loader fullpage={true} small={true} />
        ) : error && error.graphQLErrors[0].message ? (
          <Message>
            <Warning />
            {error.graphQLErrors[0].message}
            <Close />
          </Message>
        ) : data && data.success ? (
          <Success>{data.success}</Success>
        ) : (
          ''
        )}
        <Form onSubmit={formik.handleSubmit}>
          <InputContainer>
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={`InputElement ${
                formik.touched.email && formik.errors.email ? 'Invalid' : ''
              }`}
            />
            {formik.touched.email && formik.errors.email ? (
              <Error>
                <Warning />
                {formik.errors.email}
              </Error>
            ) : null}
          </InputContainer>
          <span></span>
          <Button type="submit">Submit</Button>
        </Form>
        <LoginLink href="/signin">Back to Login</LoginLink>
      </FormCard>
    </AuthBackground>
  );
};

export default ForgotPassword;
