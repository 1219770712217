import styled from 'styled-components';
import { svgs } from 'assets';

export const Timelinecontainer = styled.div`
  position: relative;
  max-width: 1200px;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #66a1ab;
    top: 0;
    bottom: 0;
    left: 2%;
    z-index: 2;
  }
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #f0f0f0;
    top: 0;
    bottom: 0;
    left: 2%;
    z-index: 1;
  }

  .container {
    padding: 0px 20px;
    background-color: inherit;
  }

  .container::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #66a1ab;
    background-image: url(${svgs.CHECK_MARK_ICON});
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    border-radius: 50%;
    z-index: 3;
  }

  .content {
    padding: 20px 30px;
    background-color: white;
    border-radius: 6px;
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #999999;
    }
  }
`;
