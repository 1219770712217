import gql from 'graphql-tag';

export const GET_CLIENT_BATCHES = gql`
  query batches($client: String, $search: String) {
    batches(client: $client, search: $search) {
      id
      name
    }
  }
`;

export const GET_REQUESTS = gql`
  query requestList(
    $search: String
    $clientId: UUID
    $limit: Int
    $offset: Int
    $ordering: String
    $status: String
  ) {
    verificationRequestList(
      search: $search
      clientId: $clientId
      limit: $limit
      offset: $offset
      ordering: $ordering
      candidateStatus: $status
    ) {
      totalCount
      requestsCount
      pending
      processing
      completed
      results {
        id
        candidate(status: $status) {
          id
          firstname
          lastname
          email
          status
        }
        client {
          id
          name
        }
        batch {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query clients($search: String, $limit: Int) {
    clientList(search: $search, limit: $limit) {
      results {
        id
        name
      }
    }
  }
`;

export const GET_SINGLE_CLIENT = gql`
  query client($id: UUID!) {
    client(id: $id) {
      id
      name
      verificationTypes {
        id
        name
      }
    }
  }
`;

export const GET_CLIENTS_CANDIDATES = gql`
  query clientCandidateList(
    $offset: Int
    $limit: Int
    $id: UUID
    $requestSent: String
    $batch: String
    $search: String
  ) {
    clientCandidateList(
      id: $id
      offset: $offset
      limit: $limit
      requestSent: $requestSent
      batch: $batch
      search: $search
    ) {
      totalCount
      results {
        id
        firstname
        lastname
        address
        phone
        email
        status
        client {
          id
        }
        batch {
          id
          name
        }
        verCandidates {
          status
          id
        }
      }
    }
  }
`;

export const GET_CANDIDATE = gql`
  query candidate($id: UUID!) {
    candidate(id: $id) {
      firstname
      lastname
      email
      verCandidates {
        id
        requestTypes
      }
    }
  }
`;

export const REINITIATE_REQUEST = gql`
  mutation updateVerificationRequestTypes(
    $verId: UUID!
    $requestTypes: [UUID]!
    $verStatus: String!
  ) {
    updateVerificationRequestTypes(
      verId: $verId
      requestTypes: $requestTypes
      verStatus: $verStatus
    ) {
      ok
      errors {
        messages
      }
      verfRequest {
        id
      }
    }
  }
`;
export const INITIATE_REQUEST = gql`
  mutation verificationRequest(
    $clientId: UUID!
    $candidateId: UUID!
    $requestTypes: [UUID]!
    $requestStatus: String!
  ) {
    createVerificationRequest(
      verificationRequest: {
        clientId: $clientId
        candidateId: $candidateId
        requestTypes: $requestTypes
        requestStatus: $requestStatus
      }
    ) {
      ok
      errors {
        messages
      }
      verfRequest {
        id
      }
    }
  }
`;

export const INITIATE_BULK_REQUEST = gql`
  mutation bulkVerificationRequest(
    $verificationRequests: BulkVerificationRequestInputType!
  ) {
    createBulkVerification(verificationRequests: $verificationRequests) {
      ok
      errors {
        messages
      }
      success
    }
  }
`;

export const GET_TIMELINE_LIST = gql`
  query timelineList($optionId: UUID!, $verificationId: UUID!) {
    timelineList(optionId: $optionId, verificationId: $verificationId) {
      totalCount
      results {
        id
        action
        createdAt
        updatedAt
      }
    }
  }
`;
