import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FieldArray } from 'formik';

import { CREATE_REFERENCE } from 'graphql/resolvers/admin/candidates/mutations';
import { AcademicForm, FormFooter, AddSection } from '../styles';
import Container from 'components/common/Container';
import { Form, InputContainer } from 'pages/Admin/Clients/styles';
import FormUploader from 'components/Uploader';

import { referenceValidationSchema } from 'helpers/validators';
import { Error } from '../../Signin/styles';

import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { toastSuccess, toastError } from 'components/common/Toast';
import { svgs } from 'assets';

const Reference = ({
  nextTab,
  prevTab,
  index,
  newInfo,
  setNewinfo,
  savedData,
  ...props
}) => {
  const [showLoader, setLoaderState] = useState(false);

  const [createReferenceRequest] = useMutation(CREATE_REFERENCE);

  const handleOnSubmit = async (values) => {
    const payload = [];
    setLoaderState(true);
    for (let i = 0; i < values.referenceDetails.length; i++) {
      const response = createReferenceRequest({
        variables: {
          verificationRequest: localStorage.getItem('verificationId'),
          id: values.referenceDetails?.[i].id ? values.referenceDetails?.[i].id : null,
          name: values.referenceDetails?.[i].referenceName,
          email: values.referenceDetails?.[i].email,
          officialEmail: values.referenceDetails?.[i].officialEmail,
          phone: values.referenceDetails?.[i].phone,
          address: values.referenceDetails?.[i].address,
          officeAddress: values.referenceDetails?.[i].officeAddress
        }
      });
      payload.push(response);
    }
    const results = await Promise.allSettled(payload);

    const dataLoads = [];

    results.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        dataLoads.push(value.data.createReferenceRequest.reference);
      }
    });

    const referenceDetails = dataLoads?.length
      ? dataLoads.map((ref) => ({
          id: ref.id,
          referenceName: ref.name,
          email: ref.email,
          officialEmail: ref.officialEmail,
          phone: ref.phone,
          address: ref.address,
          officeAddress: ref.officeAddress
        }))
      : [];

    setNewinfo((prev) => ({
      ...prev,
      reference: {
        referenceDetails
      }
    }));

    if (dataLoads.length) {
      toastSuccess(svgs.SUCCESS_ICON, 'Reference Request Submitted Successfully');
      nextTab(index);
    } else {
      toastError(svgs.DELETE_ICON, 'An error has occured');
    }

    setLoaderState(false);
  };

  return (
    <Formik
      initialValues={{
        referenceDetails:
          newInfo?.reference?.referenceDetails.length || savedData.length
            ? newInfo?.reference?.referenceDetails || savedData
            : [
                {
                  id: '',
                  referenceName: '',
                  email: '',
                  officialEmail: '',
                  phone: '',
                  address: '',
                  officeAddress: ''
                },
                {
                  id: '',
                  referenceName: '',
                  email: '',
                  officialEmail: '',
                  phone: '',
                  address: '',
                  officeAddress: ''
                }
              ]
      }}
      validationSchema={referenceValidationSchema}
      onSubmit={handleOnSubmit}
      render={({
        values,
        errors,
        touched,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur
      }) => {
        return (
          <Container>
            <AcademicForm professional>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <FieldArray
                  name="referenceDetails"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.referenceDetails.length > 0 &&
                        values.referenceDetails.map((reference, index) => (
                          <div key={index} style={{ padding: '30px' }}>
                            <InputContainer>
                              <label htmlFor="referenceName">Name of Reference</label>
                              <input
                                id="referenceName"
                                name={`referenceDetails.${index}.referenceName`}
                                type="text"
                                placeholder="Reference Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.referenceDetails?.[index].referenceName}
                                className={`InputElement ${
                                  touched?.referenceDetails?.[index]?.referenceName &&
                                  errors?.referenceDetails?.[index]?.referenceName
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.referenceDetails?.[index]?.referenceName &&
                              errors?.referenceDetails?.[index]?.referenceName ? (
                                <Error>
                                  {errors?.referenceDetails?.[index]?.referenceName}
                                </Error>
                              ) : null}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="email">Personal Email</label>
                              <input
                                id="email"
                                name={`referenceDetails.${index}.email`}
                                type="text"
                                placeholder="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.referenceDetails?.[index].email}
                                className={`InputElement ${
                                  touched?.referenceDetails?.[index]?.email &&
                                  errors?.referenceDetails?.[index]?.email
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.referenceDetails?.[index]?.email &&
                              errors?.referenceDetails?.[index]?.email ? (
                                <Error>{errors?.referenceDetails?.[index]?.email}</Error>
                              ) : null}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="phone">Phone Number</label>
                              <input
                                id="phone"
                                name={`referenceDetails.${index}.phone`}
                                type="number"
                                placeholder="Phone Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.referenceDetails?.[index].phone}
                                className={`InputElement ${
                                  touched?.referenceDetails?.[index]?.phone &&
                                  errors?.referenceDetails?.[index]?.phone
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.referenceDetails?.[index]?.phone &&
                              errors?.referenceDetails?.[index]?.phone ? (
                                <Error>{errors?.referenceDetails?.[index]?.phone}</Error>
                              ) : null}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="Address">Residential Address</label>
                              <input
                                id="address"
                                name={`referenceDetails.${index}.address`}
                                type="text"
                                placeholder="Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.referenceDetails?.[index].address}
                                className={`InputElement ${
                                  touched?.referenceDetails?.[index]?.address &&
                                  errors?.referenceDetails?.[index]?.address
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.referenceDetails?.[index]?.address &&
                              errors?.referenceDetails?.[index]?.address ? (
                                <Error>
                                  {errors?.referenceDetails?.[index]?.address}
                                </Error>
                              ) : null}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="email">Official Email</label>
                              <input
                                id="officialEmail"
                                name={`referenceDetails.${index}.officialEmail`}
                                type="text"
                                placeholder="Offical Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.referenceDetails?.[index].officialEmail}
                                className={`InputElement ${
                                  touched?.referenceDetails?.[index]?.officialEmail &&
                                  errors?.referenceDetails?.[index]?.officialEmail
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.referenceDetails?.[index]?.officialEmail &&
                              errors?.referenceDetails?.[index]?.officialEmail ? (
                                <Error>
                                  {errors?.referenceDetails?.[index]?.officialEmail}
                                </Error>
                              ) : null}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="Address">Official Address</label>
                              <input
                                id="officeAddress"
                                name={`referenceDetails.${index}.officeAddress`}
                                type="text"
                                placeholder="Offical Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.referenceDetails?.[index].officeAddress}
                                className={`InputElement ${
                                  touched?.referenceDetails?.[index]?.officeAddress &&
                                  errors?.referenceDetails?.[index]?.officeAddress
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.referenceDetails?.[index]?.officeAddress &&
                              errors?.referenceDetails?.[index]?.officeAddress ? (
                                <Error>
                                  {errors?.referenceDetails?.[index]?.officeAddress}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <AddSection>
                              <AddSection>
                                {index > 1 && (
                                  <button
                                    className="text-red"
                                    bgColor="transparent"
                                    textColor="#000"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    REMOVE
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => {
                                    push({
                                      referenceName: '',
                                      email: '',
                                      officialEmail: '',
                                      phone: '',
                                      address: '',
                                      officeAddress: ''
                                    });
                                  }}
                                >
                                  + Add Reference
                                </button>
                              </AddSection>
                            </AddSection>
                          </div>
                        ))}
                    </>
                  )}
                />
                <FormFooter>
                  <Button backbutton type="button" onClick={() => prevTab(index)}>
                    Back
                  </Button>
                  <Button type="submit" disabled={showLoader ? true : false}>
                    {showLoader ? (
                      <div style={{ textAlign: 'center' }}>
                        <Loader />
                      </div>
                    ) : (
                      'Next'
                    )}
                  </Button>
                </FormFooter>
              </Form>
            </AcademicForm>
          </Container>
        );
      }}
    />
  );
};

export default Reference;
