import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

import { CREATE_EMPLOYERS } from 'graphql/resolvers/admin/candidates/mutations';
import {
  AcademicForm,
  ConsentSection,
  FormFooter,
  RoundedButton,
  AddSection
} from '../styles';
import Container from 'components/common/Container';
import { Form, InputContainer } from 'pages/Admin/Clients/styles';

import { employerValidationSchema } from 'helpers/validators';
import { Error } from '../../Signin/styles';

import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import FormUploader from 'components/Uploader';
import { toastSuccess, toastError } from 'components/common/Toast';
import { svgs } from 'assets';
import { fileDownloader } from './../../../helpers/fileDownloader';

const Employer = ({
  nextTab,
  prevTab,
  index,
  setNewinfo,
  newInfo,
  savedData,
  ...props
}) => {
  const [showLoader, setLoaderState] = useState(false);
  const [createEmploymentRequest] = useMutation(CREATE_EMPLOYERS);

  const handleOnSubmit = async (values) => {
    const payload = [];
    setLoaderState(true);
    for (let i = 0; i < values.employerDetails.length; i++) {
      const response = createEmploymentRequest({
        variables: {
          verificationRequest: localStorage.getItem('verificationId'),
          id: values.employerDetails?.[i].id ? values.employerDetails?.[i].id : null,
          employerName: values.employerDetails?.[i].name,
          contactPerson: values.employerDetails?.[i].contactPerson,
          contactPersonRole: values.employerDetails?.[i].contactPersonRole,
          email: values.employerDetails?.[i].email,
          address: values.employerDetails?.[i].address,
          phone: values.employerDetails?.[i].phone
        }
      });
      payload.push(response);
    }
    const results = await Promise.allSettled(payload);

    const dataLoads = [];

    results.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        dataLoads.push(value.data.createEmploymentRequest.employment);
      }
    });

    const employerDetails = dataLoads?.length
      ? dataLoads.map((emp) => ({
          id: emp.id,
          name: emp.employerName,
          contactPerson: emp.contactPerson,
          contactPersonRole: emp.contactPersonRole,
          email: emp.email,
          address: emp.address,
          phone: emp.phone
        }))
      : [];

    setNewinfo((prev) => ({
      ...prev,
      employment: {
        employerDetails
      }
    }));

    if (dataLoads.length) {
      toastSuccess(svgs.SUCCESS_ICON, 'Employment Request Submitted Successfully');
      nextTab(index);
    } else {
      toastError(svgs.DELETE_ICON, 'An error has occured');
    }

    setLoaderState(false);
  };

  return (
    <Formik
      initialValues={{
        employerDetails:
          newInfo?.employment?.employerDetails.length || savedData.length
            ? newInfo?.employment?.employerDetails || savedData
            : [
                {
                  id: '',
                  name: '',
                  contactPerson: '',
                  contactPersonRole: '',
                  email: '',
                  address: '',
                  phone: ''
                }
              ]
      }}
      validationSchema={employerValidationSchema}
      onSubmit={handleOnSubmit}
      render={({
        values,
        errors,
        touched,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur
      }) => {
        return (
          <Container>
            <AcademicForm professional>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <FieldArray
                  name="employerDetails"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.employerDetails.length > 0 &&
                        values.employerDetails.map((employer, index) => (
                          <div key={index} style={{ padding: '30px' }}>
                            <InputContainer>
                              <label>Employer Name</label>
                              <input
                                type="text"
                                name={`employerDetails.${index}.name`}
                                placeholder="Employer Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employerDetails?.[index].name}
                                className={`InputElement ${
                                  touched?.employerDetails?.[index]?.name &&
                                  errors?.employerDetails?.[index]?.name
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.employerDetails?.[index]?.name &&
                              errors?.employerDetails?.[index]?.name ? (
                                <Error>{errors?.employerDetails?.[index]?.name}</Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>Contact Person</label>
                              <input
                                type="text"
                                name={`employerDetails.${index}.contactPerson`}
                                placeholder="Contact Person"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employerDetails?.[index].contactPerson}
                                className={`InputElement ${
                                  touched?.employerDetails?.[index]?.contactPerson &&
                                  errors?.employerDetails?.[index]?.contactPerson
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.employerDetails?.[index]?.contactPerson &&
                              errors?.employerDetails?.[index]?.contactPerson ? (
                                <Error>
                                  {errors?.employerDetails?.[index]?.contactPerson}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>Contact Person Role</label>
                              <input
                                type="text"
                                name={`employerDetails.${index}.contactPersonRole`}
                                placeholder="Contact Person Role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employerDetails?.[index].contactPersonRole}
                                className={`InputElement ${
                                  touched?.employerDetails?.[index]?.contactPersonRole &&
                                  errors?.employerDetails?.[index]?.contactPersonRole
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.employerDetails?.[index]?.contactPersonRole &&
                              errors?.employerDetails?.[index]?.contactPersonRole ? (
                                <Error>
                                  {errors?.employerDetails?.[index]?.contactPersonRole}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>Email</label>
                              <input
                                type="text"
                                name={`employerDetails.${index}.email`}
                                placeholder="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employerDetails?.[index].email}
                                className={`InputElement ${
                                  touched?.employerDetails?.[index]?.email &&
                                  errors?.employerDetails?.[index]?.email
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.employerDetails?.[index]?.email &&
                              errors?.employerDetails?.[index]?.email ? (
                                <Error>{errors?.employerDetails?.[index]?.email}</Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>Phone number</label>
                              <input
                                name={`employerDetails.${index}.phone`}
                                type="number"
                                placeholder="Phone Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employerDetails?.[index].phone}
                                className={`InputElement ${
                                  touched?.employerDetails?.[index]?.phone &&
                                  errors?.employerDetails?.[index]?.phone
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.employerDetails?.[index]?.phone &&
                              errors?.employerDetails?.[index]?.phone ? (
                                <Error>{errors?.employerDetails?.[index]?.phone}</Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>Address</label>
                              <input
                                name={`employerDetails.${index}.address`}
                                type="text"
                                placeholder="Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employerDetails?.[index].address}
                                className={`InputElement ${
                                  touched?.employerDetails?.[index]?.address &&
                                  errors?.employerDetails?.[index]?.address
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.employerDetails?.[index]?.address &&
                              errors?.employerDetails?.[index]?.address ? (
                                <Error>{errors?.employerDetails?.[index]?.address}</Error>
                              ) : null}
                            </InputContainer>
                            <AddSection>
                              <AddSection>
                                {index > 0 && (
                                  <button
                                    className="text-red"
                                    bgColor="transparent"
                                    textColor="#000"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    REMOVE
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => {
                                    push({
                                      name: '',
                                      email: '',
                                      address: '',
                                      phone: ''
                                    });
                                  }}
                                >
                                  + Add Employment
                                </button>
                              </AddSection>
                            </AddSection>
                          </div>
                        ))}
                    </>
                  )}
                />
                <FormFooter>
                  <Button backbutton type="button" onClick={() => prevTab(index)}>
                    Back
                  </Button>
                  <Button type="submit" disabled={showLoader ? true : false}>
                    {showLoader ? (
                      <div style={{ textAlign: 'center' }}>
                        <Loader />
                      </div>
                    ) : (
                      'Next'
                    )}
                  </Button>
                </FormFooter>
              </Form>
            </AcademicForm>
          </Container>
        );
      }}
    />
  );
};

export default Employer;
