import styled, { keyframes } from 'styled-components';

const Zoom = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  width: 40%;
  height: 40%;
  animation: ${Zoom} 30s infinite;
  -webkit-animation: ${Zoom} 0.2s infinite;
`;
