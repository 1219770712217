import styled from 'styled-components';
import { primary } from '../../constants/colors';
import { svgs } from 'assets';

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  background: #fff;
  border-bottom: 4px solid rgba(52, 185, 145, 0.05);
  min-height: 70px;
  padding: 0 15%;
`;

//Logo Container Styles
export const LogoContainer = styled.div`
  display: flex;
  justify-items: start;
  align-items: center;
`;

//Navigation Styles
export const NavigationContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const Nav = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  margin: 0;
  height: 100%;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      padding: 20px;
      text-decoration: none;
      display: block;
      position: relative;
      color: #999;
      font-size: 14px;
      font-weight: 500;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        background: ${primary};
        opacity: 0;
        left: 0;
        transition: 0.3s;
        bottom: -12px;
      }

      &:hover {
        color: #333;

        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

//The profile Icon and notification Icon Styles
export const ProfileNavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

export const NotificationIconContainer = styled.div`
  padding: 10px 20px;
  position: relative;
`;

export const NotificationCount = styled.span`
  position: absolute;
  width: auto;
  background: #eb5757;
  color: #fff;
  border-radius: 100%;
  font-size: 6px;
  left: 28px;
  bottom: 20px;
  padding: 5px;
  width: 16px;
  height: 16px;
  text-align: center;
`;

export const ProfileImage = styled.span`
  cursor: pointer;
`;

export const ProfileName = styled.span`
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const DropdownHead = styled.div`
  text-decoration: none;
  padding: 15px;
  background-color: #fff;
  align-items: center;
`;
export const Dropdown = styled.div`
  position: relative;
  top: ${({ view }) => (view === 'nav' ? '8px' : '0px')};
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    outline: none;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 2px;
  }

  .dropdown-content a,
  h5,
  p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }
  p {
    color: #eb5757;
  }

  .dropdown a:hover {
    background-color: #ddd;
  }

  .show {
    display: block;
  }
`;

export const DOWN_ICON = styled(svgs.DOWN_ICON)`
  padding: 0 0.25rem;
  font-weight: 600;
  transform: ${({ display }) => (display ? 'rotate(180deg)' : '')};
`;
