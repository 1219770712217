import React from 'react';
import { saveAs } from 'file-saver';
import {
  Tab,
  TabItem,
  TabContent,
  FormCard,
  FormCardHeader,
  FormCardBody,
  Label,
  ClientInfoRow,
  TimelineWrapper,
  UploadBar
} from './styles';
import { svgs } from 'assets';
import Timeline from 'components/Timeline';
import { availableRoles } from 'config/constants';

const RequestTabs = ({
  tabs,
  activeTab,
  handleTabs,
  tabOnly = false,
  files,
  tabNav = true,
  timeline,
  downloadable = false,
  ...props
}) => {
  // eslint-disable-next-line require-jsdoc
  function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = undefined;
  }

  const activeTabComponent = () => tabs[activeTab]?.component;
  let groupFiles = [];
  if (files?.length > 1) {
    files.forEach((file) => {
      groupFiles.push(`crossorigin.me/${file?.url}`);
    });
  }

  const tabObject = tabs[activeTab];
  const id =
    timeline?.options &&
    timeline?.options.find((each) => each.name === tabObject?.name)?.id;

  return (
    <>
      <Tab tabOnly={tabOnly}>
        {tabs.map((tab, index) => (
          <TabItem
            key={index}
            isActive={activeTab === index}
            onClick={() => (tabNav ? handleTabs(index) : null)}
            userInfo={props.userInfo ?? null}
          >
            {tab.name}
          </TabItem>
        ))}
      </Tab>

      <TabContent tabOnly={tabOnly} space>
        <div>{activeTabComponent(props)}</div>

        <div>
          {props.userInfo?.roles[0] === availableRoles['CLIENT'] ? (
            <FormCard>
              <FormCardHeader>
                <Label>Documents</Label>
              </FormCardHeader>
              <FormCardBody>
                {files.length && files.some((each) => each.url) ? (
                  files
                    .filter((hasUrl) => !!hasUrl.url)
                    .map((file, key) => (
                      <div key={key}>
                        <UploadBar>
                          <p onClick={() => downloadURI(`${file?.url}`, `${file?.id}`)}>
                            {file?.id}
                          </p>
                          <svgs.DOWNLOAD_ICON2
                            onClick={
                              downloadable ? () => saveAs(file?.url, file?.id) : null
                            }
                          />
                        </UploadBar>
                        <br />
                      </div>
                    ))
                ) : (
                  <p>No documents</p>
                )}

                {/* {!!files.length && files.length > 1 && (
                  <DownloadLink>
                    <a
                      href="#"
                      className="download"
                      onClick={() => fileDownloader(groupFiles, `Download multiple`)}
                    >
                      Download all (.zip)
                    </a>
                  </DownloadLink>
                )} */}
              </FormCardBody>
            </FormCard>
          ) : tabOnly ? null : (
            <FormCard>
              <FormCardHeader>
                <Label>Client Information</Label>
              </FormCardHeader>
              <FormCardBody>
                <ClientInfoRow>
                  <svgs.BRIEFCASE_ICON />
                  <p>{props.clientName}</p>
                </ClientInfoRow>
                <ClientInfoRow>
                  <svgs.MAIL_ICON />
                  <p>{props.clientEmail}</p>
                </ClientInfoRow>
              </FormCardBody>
            </FormCard>
          )}
          {tabOnly ? null : (
            <>
              {/* <FormCard>
              <FormCardHeader>
                <Label>Client Information</Label>
              </FormCardHeader>
              <FormCardBody>
                <ClientInfoRow>
                  <svgs.BRIEFCASE_ICON />
                  <p>{props.clientName}</p>
                </ClientInfoRow>
                <ClientInfoRow>
                  <svgs.MAIL_ICON />
                  <p>{props.clientEmail}</p>
                </ClientInfoRow>
              </FormCardBody>
            </FormCard> */}
              <FormCard>
                <FormCardHeader>
                  <Label>Timeline</Label>
                </FormCardHeader>
                <FormCardBody>
                  <TimelineWrapper>
                    {id && (
                      <Timeline data={{ ...timeline, id }} name={tabs[activeTab]?.id} />
                    )}
                  </TimelineWrapper>
                </FormCardBody>
              </FormCard>
            </>
          )}
        </div>
      </TabContent>
    </>
  );
};

export default RequestTabs;
