import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FieldArray } from 'formik';

import { CREATE_PROFESSIONAL } from 'graphql/resolvers/admin/candidates/mutations';
import { AcademicForm, FormFooter, AddSection } from '../styles';
import Container from 'components/common/Container';
import { Form, InputContainer } from 'pages/Admin/Clients/styles';
import FormUploader from 'components/Uploader';
import { renameFileUpload } from 'utils/renameUploadUtils';

import { professionalValidationSchema } from 'helpers/validators';
import { Error } from '../../Signin/styles';

import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { toastSuccess, toastError } from 'components/common/Toast';
import { svgs } from 'assets';

const Professional = ({
  nextTab,
  prevTab,
  index,
  newInfo,
  setNewinfo,
  savedData,
  fileName,
  ...props
}) => {
  const [showLoader, setLoaderState] = useState(false);

  const [createProfessionalRequest] = useMutation(CREATE_PROFESSIONAL);

  const handleOnSubmit = async (values) => {
    const payload = [];
    setLoaderState(true);
    for (let i = 0; i < values.professionalDetails.length; i++) {
      const response = createProfessionalRequest({
        variables: {
          verificationRequest: localStorage.getItem('verificationId'),
          id: values.professionalDetails?.[i].id
            ? values.professionalDetails?.[i].id
            : null,
          awardBody: values.professionalDetails?.[i].awardingBody,
          certificateName: values.professionalDetails?.[i].certificateName,
          certificateNumber: values.professionalDetails?.[i].certificateNumber,
          dateAwarded: values.professionalDetails?.[i].dateAwarded,
          upload: values.professionalDetails?.[i].upload
        }
      });
      payload.push(response);
    }
    const results = await Promise.allSettled(payload);

    const dataLoads = [];

    results.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        dataLoads.push(value.data.createProfessionalRequest.professional);
      }
    });

    const professionalDetails = dataLoads?.length
      ? dataLoads.map((prof) => ({
          id: prof.id,
          awardingBody: prof.awardBody,
          certificateName: prof.certificateName,
          certificateNumber: prof.certificateNumber,
          dateAwarded: prof.dateAwarded,
          upload: prof.upload
        }))
      : [];

    setNewinfo((prev) => ({
      ...prev,
      professional: {
        professionalDetails
      }
    }));

    if (dataLoads.length) {
      toastSuccess(svgs.SUCCESS_ICON, 'Professional Request Submitted Successfully');
      nextTab(index);
    } else {
      toastError(svgs.DELETE_ICON, 'An error has occured');
    }

    setLoaderState(false);
  };

  return (
    <Formik
      initialValues={{
        professionalDetails:
          newInfo?.professional?.professionalDetails.length || savedData.length
            ? newInfo?.professional?.professionalDetails || savedData
            : [
                {
                  id: '',
                  awardingBody: '',
                  certificateName: '',
                  certificateNumber: '',
                  dateAwarded: '',
                  upload: ''
                }
              ]
      }}
      validationSchema={professionalValidationSchema}
      onSubmit={handleOnSubmit}
      render={({
        values,
        errors,
        touched,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur
      }) => {
        return (
          <Container>
            <AcademicForm professional>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <FieldArray
                  name="professionalDetails"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.professionalDetails.length > 0 &&
                        values.professionalDetails.map((education, index) => (
                          <div key={index} style={{ padding: '30px' }}>
                            <InputContainer>
                              <label>Name of awarding body</label>
                              <input
                                type="text"
                                id="awardingBody"
                                name={`professionalDetails.${index}.awardingBody`}
                                placeholder="Awarding Body"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.professionalDetails?.[index].awardingBody}
                                className={`InputElement ${
                                  touched?.professionalDetails?.[index]?.awardingBody &&
                                  errors?.professionalDetails?.[index]?.awardingBody
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.professionalDetails?.[index]?.awardingBody &&
                              errors?.professionalDetails?.[index]?.awardingBody ? (
                                <Error>
                                  {errors?.professionalDetails?.[index]?.awardingBody}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>certificate/membership name</label>
                              <input
                                type="text"
                                id="certificateName"
                                name={`professionalDetails.${index}.certificateName`}
                                placeholder="Certificate Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.professionalDetails?.[index].certificateName
                                }
                                className={`InputElement ${
                                  touched?.professionalDetails?.[index]
                                    ?.certificateName &&
                                  errors?.professionalDetails?.[index]?.certificateName
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.professionalDetails?.[index]?.certificateName &&
                              errors?.professionalDetails?.[index]?.certificateName ? (
                                <Error>
                                  {errors?.professionalDetails?.[index]?.certificateName}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>certificate/membership number</label>
                              <input
                                type="text"
                                id="certificateNumber"
                                name={`professionalDetails.${index}.certificateNumber`}
                                placeholder="Certificate Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.professionalDetails?.[index].certificateNumber
                                }
                                className={`InputElement ${
                                  touched?.professionalDetails?.[index]
                                    ?.certificateNumber &&
                                  errors?.professionalDetails?.[index]?.certificateNumber
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.professionalDetails?.[index]?.certificateNumber &&
                              errors?.professionalDetails?.[index]?.certificateNumber ? (
                                <Error>
                                  {
                                    errors?.professionalDetails?.[index]
                                      ?.certificateNumber
                                  }
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label>DATE AWARDED</label>
                              <input
                                type="date"
                                id="dateAwarded"
                                name={`professionalDetails.${index}.dateAwarded`}
                                placeholder="YYYY-MM-DD"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.professionalDetails?.[index].dateAwarded}
                                className={`InputElement ${
                                  touched?.professionalDetails?.[index]?.dateAwarded &&
                                  errors?.professionalDetails?.[index]?.dateAwarded
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.professionalDetails?.[index]?.dateAwarded &&
                              errors?.professionalDetails?.[index]?.dateAwarded ? (
                                <Error>
                                  {errors?.professionalDetails?.[index]?.dateAwarded}
                                </Error>
                              ) : null}
                            </InputContainer>

                            <InputContainer>
                              <label htmlFor="">Professional certificate</label>
                              <FormUploader
                                form
                                accept={[
                                  'application/pdf',
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg'
                                ]}
                                className={
                                  touched?.professionalDetails?.[index]?.upload &&
                                  errors?.professionalDetails?.[index]?.upload
                                    ? 'Invalid'
                                    : ''
                                }
                                handleUpload={(files) => {
                                  const modifiedFile = files[0];
                                  // const modifiedFile = renameFileUpload(
                                  //   file,
                                  //   fileName || 'Olevel_Certificate'
                                  // );
                                  setFieldValue(
                                    `professionalDetails.${index}.upload`,
                                    modifiedFile
                                  );
                                }}
                                text="Upload File"
                                file={values?.professionalDetails?.[index]?.upload}
                              />
                              {touched?.professionalDetails?.[index]?.upload &&
                              errors?.professionalDetails?.[index]?.upload ? (
                                <Error>
                                  {errors?.professionalDetails?.[index]?.upload}
                                </Error>
                              ) : null}
                            </InputContainer>
                            <AddSection>
                              <AddSection>
                                {index > 0 && (
                                  <button
                                    className="text-red"
                                    bgColor="transparent"
                                    textColor="#000"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    REMOVE
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => {
                                    push({
                                      awardingBody: '',
                                      certificateName: '',
                                      certificateNumber: '',
                                      dateAwarded: '',
                                      upload: ''
                                    });
                                  }}
                                >
                                  + Add Professional
                                </button>
                              </AddSection>
                            </AddSection>
                          </div>
                        ))}
                    </>
                  )}
                />
                <FormFooter>
                  <Button backbutton type="button" onClick={() => prevTab(index)}>
                    Back
                  </Button>
                  <Button type="submit" disabled={showLoader ? true : false}>
                    {showLoader ? (
                      <div style={{ textAlign: 'center' }}>
                        <Loader />
                      </div>
                    ) : (
                      'Next'
                    )}
                  </Button>
                </FormFooter>
              </Form>
            </AcademicForm>
          </Container>
        );
      }}
    />
  );
};

export default Professional;
