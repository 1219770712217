import styled from 'styled-components';

export const Tab = styled.div`
  background: #fcfcfc;
  display: flex;
  padding: 20px 10px 10px;
  // ${({ tabOnly }) => (tabOnly ? 'justify-content: center' : null)}
  flex-wrap: wrap;
`;

export const TabItem = styled.p`
  position: relative;
  padding: 5px 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: grid;
  text-transform: capitalize;
  color: #666666;
  text-align: center;
  margin: 20px 20px 0;

  &::after {
    content: '';
    height: 2px;
    width: 100%;
    transition: ease 0.5s;
    position: absolute;
    bottom: 0;
    background: ${({ isActive }) => (isActive ? '#666666' : 'none')};
    margin-bottom: -10px;
  }
`;

export const TabContent = styled.div`
  display: grid;
  grid-template-columns: ${({ tabOnly }) => (tabOnly ? '1fr' : '2fr 1fr')};
  column-gap: 10px;
  margin-top: 10px;
  margin-top: ${({ space }) => (space ? '10px' : '0px')};
`;

export const FormCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 2px;
  margin-bottom: 10px;
  padding-top: 10px;
  column-gap: 10px;
  ${({ tabOnly }) => (tabOnly ? 'width: 500px' : null)}
`;

export const FormCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fcfcfc;
  padding: 15px 29px;
`;

export const FormCardHeaderAction = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #408996;
  cursor: pointer;
`;

export const FormCardBody = styled.div`
  background: #ffffff;
  padding: 10px 29px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #999999;
`;

export const Input = styled.input`
  width: 70%;
  border: none;
  padding: 20px;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;

  color: #666666;
`;

export const FormCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 29px;
`;

export const UploadBar = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  column-gap: 10px;
  border: 1px solid rgba(64, 137, 150, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #408996;
    margin: 0 5px;
  }
`;

export const DownloadLink = styled.div`
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #408996;
    text-decoration: underline;
  }
`;

export const SelectDropDown = styled.select`
  border: 1px solid rgba(64, 137, 150, 0.25);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

export const ClientInfoRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 7fr;
  margin-bottom: 10px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    margin: 0;
  }
`;

export const TimelineWrapper = styled.div`
  height: 400px;
  overflow: scroll;
`;
