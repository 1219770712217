import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_ADDRESS } from 'graphql/resolvers/admin/candidates/mutations';
import * as Yup from 'yup';
import { addressValidationSchema } from 'helpers/validators';

import { InputContainer, Form } from '../../Admin/Clients/styles';
import { AcademicForm, FormFooter } from '../styles';
import { Error } from 'pages/Signin/styles';
import { toastSuccess, toastError } from 'components/common/Toast';

import Container from 'components/common/Container';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import { svgs } from 'assets';

const Address = ({
  nextTab,
  prevTab,
  index,
  newInfo,
  setNewinfo,
  savedData,
  ...props
}) => {
  const [showLoader, setLoaderState] = useState(false);
  const [createAddressRequest] = useMutation(CREATE_ADDRESS);

  const formik = useFormik({
    initialValues: {
      id: '',
      address: '',
      phoneNumber: '',
      contactPerson: '',
      busStop: '',
      landmark: '',
      city: '',
      localGovt: '',
      state: '',
      ...savedData[savedData.length - 1],
      ...newInfo?.address
    },
    validationSchema: Yup.object({
      ...addressValidationSchema
    }),
    onSubmit: async (values) => {
      setLoaderState(true);
      try {
        const response = await createAddressRequest({
          variables: {
            verificationRequest: localStorage.getItem('verificationId'),
            id: values.id ? values.id : null,
            address: values.address,
            phoneNumber: values.phoneNumber,
            contactPerson: values.contactPerson,
            nearestBusStop: values.busStop,
            nearestLandmark: values.landmark,
            city: values.city,
            localGovernment: values.localGovt,
            state: values.state
          }
        });
        const { ok, errors } = response.data.createAddressRequest;
        setLoaderState(false);
        if (errors) {
          const { messages } = errors;
          return toastError('', messages);
        }
        if (!errors & ok) {
          setNewinfo((prev) => ({
            ...prev,
            address: values
          }));
          toastSuccess(svgs.SUCCESS_ICON, 'Address Request Submitted Successfully');
          nextTab(index);
        }
      } catch (error) {
        const { message } = error;
        toastError(svgs.DELETE_ICON, message);
      }
      return setLoaderState(false);
    }
  });

  return (
    <Container>
      <AcademicForm reference>
        <Form onSubmit={formik.handleSubmit}>
          <div style={{ padding: '30px' }}>
            <InputContainer>
              <label htmlFor="Address">Address of candidate</label>
              <input
                id="address"
                name="address"
                type="text"
                placeholder="Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                className={`InputElement ${
                  formik.touched.address && formik.errors.address ? 'Invalid' : ''
                }`}
              />
              {formik.touched.address && formik.errors.address ? (
                <Error>{formik.errors.address}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                placeholder="Phone Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className={`InputElement ${
                  formik.touched.phoneNumber && formik.errors.phoneNumber ? 'Invalid' : ''
                }`}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <Error>{formik.errors.phoneNumber}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="contactPerson">Contact Person</label>
              <input
                id="contactPerson"
                name="contactPerson"
                type="text"
                placeholder="Contact Person"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactPerson}
                className={`InputElement ${
                  formik.touched.contactPerson && formik.errors.contactPerson
                    ? 'Invalid'
                    : ''
                }`}
              />
              {formik.touched.contactPerson && formik.errors.contactPerson ? (
                <Error>{formik.errors.contactPerson}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="nearestBusstop">Nearest Busstop</label>
              <input
                id="busStop"
                name="busStop"
                type="text"
                placeholder="Nearest Busstop"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.busStop}
                className={`InputElement ${
                  formik.touched.busStop && formik.errors.busStop ? 'Invalid' : ''
                }`}
              />
              {formik.touched.busStop && formik.errors.busStop ? (
                <Error>{formik.errors.busStop}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="landmark">Nearest landmark</label>
              <input
                id="landmark"
                name="landmark"
                type="text"
                placeholder="Nearest Landmark"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.landmark}
                className={`InputElement ${
                  formik.touched.landmark && formik.errors.landmark ? 'Invalid' : ''
                }`}
              />
              {formik.touched.landmark && formik.errors.landmark ? (
                <Error>{formik.errors.landmark}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="city">City</label>
              <input
                id="city"
                name="city"
                type="text"
                placeholder="City"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                className={`InputElement ${
                  formik.touched.city && formik.errors.city ? 'Invalid' : ''
                }`}
              />
              {formik.touched.city && formik.errors.city ? (
                <Error>{formik.errors.city}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="localGovt">Local Government</label>
              <input
                id="localGovt"
                name="localGovt"
                type="text"
                placeholder="Local Government"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.localGovt}
                className={`InputElement ${
                  formik.touched.localGovt && formik.errors.localGovt ? 'Invalid' : ''
                }`}
              />
              {formik.touched.localGovt && formik.errors.localGovt ? (
                <Error>{formik.errors.localGovt}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="state">State</label>
              <input
                id="state"
                name="state"
                type="text"
                placeholder="State"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                className={`InputElement ${
                  formik.touched.state && formik.errors.state ? 'Invalid' : ''
                }`}
              />
              {formik.touched.state && formik.errors.state ? (
                <Error>{formik.errors.state}</Error>
              ) : null}
            </InputContainer>
          </div>

          <FormFooter>
            <Button backbutton onClick={() => prevTab(index)} type="button">
              Back
            </Button>
            <Button type="submit">
              {showLoader ? (
                <div style={{ textAlign: 'center' }}>
                  <Loader />
                </div>
              ) : (
                'Next'
              )}
            </Button>
          </FormFooter>
        </Form>
      </AcademicForm>
    </Container>
  );
};

export default Address;
