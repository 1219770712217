import gql from 'graphql-tag';

export const GET_AUTH_USER_QUERY = gql`
  query GetAuthUser {
    AuthUser @client {
      id
      firstname
      lastname
      image
      isAuthenticated
      email
      roles
      client {
        id
      }
    }
  }
`;

export const getAuthUserQuery = (_, v, { cache }) => {
  const data = cache.readQuery({
    query: GET_AUTH_USER_QUERY,
  });

  return data;
};
