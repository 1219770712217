import { ReactComponent as LOGO } from './svgs/logo.svg';
import { ReactComponent as EYE } from './svgs/eye.svg';
import { ReactComponent as HIDE_EYE } from './svgs/hide-eye.svg';
import { ReactComponent as CLOSE } from 'assets/svgs/close.svg';
import { ReactComponent as WARNING } from 'assets/svgs/warning.svg';
import { ReactComponent as EMPTY_AVATAR } from 'assets/svgs/empty_avatar.svg';
import { ReactComponent as CARET_DOWN } from 'assets/svgs/caret-down.svg';
import { ReactComponent as SEARCH } from 'assets/svgs/search.svg';
import { ReactComponent as BACK_BUTTON } from 'assets/svgs/back-button.svg';
import { ReactComponent as FORWARD_BUTTON } from 'assets/svgs/forward-button.svg';
import { ReactComponent as DOWNLOAD_ICON2 } from 'assets/svgs/download.svg';
import { ReactComponent as DOWNLOAD_CLOUD } from 'assets/svgs/download-cloud.svg';
import { ReactComponent as BRIEFCASE_ICON } from 'assets/svgs/briefcase.svg';
import { ReactComponent as MAIL_ICON } from 'assets/svgs/mail.svg';
import { ReactComponent as COMMENT_ICON } from 'assets/svgs/comment.svg';
import { ReactComponent as DOWN_ICON } from 'assets/svgs/down-icon.svg';
import { ReactComponent as UP_ICON } from 'assets/svgs/up-icon.svg';
// import { ReactComponent as DOWNLOAD_CLOUD } from 'assets/svgs/download-cloud.svg';
import CHECK_MARK_ICON from 'assets/svgs/check-mark.svg';
const PATH = './pngs/';
const SVGPATH = './svgs/';
const DOCPATH = './docs/';

const pngs = {
  //   ZTPLOGO: require(`${PATH}ztp-logo-2.png`),
  ImgPNG: require(`${PATH}icons-PNG.png`)
};

const docs = {
  CSV: require(`${DOCPATH}sample_institution.csv`),
  Candidates: require(`${DOCPATH}sample_candidates.csv`)
};

const svgs = {
  //   CIRCLES: require(`${SVGPATH}circles.svg`),
  LOGO,
  LOGIN_BACKGROUND: require(`${SVGPATH}background.svg`),
  EYE,
  HIDE_EYE,
  CLOSE,
  WARNING,
  CARET_DOWN,
  SEARCH,
  BACK_BUTTON,
  FORWARD_BUTTON,
  DOWNLOAD_ICON2,
  DOWNLOAD_CLOUD,
  BRIEFCASE_ICON,
  MAIL_ICON,
  COMMENT_ICON,
  CHECK_MARK_ICON,
  DOWN_ICON,
  UP_ICON,
  EMPTY_AVATAR: require(`${SVGPATH}empty_avatar.svg`),
  NOTIFICATION_ICON: require(`${SVGPATH}notification.svg`),
  AVATAR: require(`${SVGPATH}avatar.svg`),
  EMPTY: require(`${SVGPATH}empty.svg`),
  MORE_ICON: require(`${SVGPATH}more.svg`),
  CLOSE_ICON: require(`${SVGPATH}close.svg`),
  GREEN_FOLDER: require(`${SVGPATH}folder-green.svg`),
  FILE_TYPE: require(`${SVGPATH}file-type.svg`),
  CHECKED_ICON: require(`${SVGPATH}checkbox_checked.svg`),
  UNCHECKED_ICON: require(`${SVGPATH}checkbox_unchecked.svg`),
  BACK_ICON: require(`${SVGPATH}back.svg`),
  SEARCH_ICON: require(`${SVGPATH}search.svg`),
  LEFT_ICON: require(`${SVGPATH}left.svg`),
  RIGHT_ICON: require(`${SVGPATH}right.svg`),
  ARROW_DOWN: require(`${SVGPATH}arrow_down.svg`),
  SUCCESS_ICON: require(`${SVGPATH}success_icon.svg`),
  DELETE_ICON: require(`${SVGPATH}delete_icon.svg`),
  UPLOAD_ICON: require(`${SVGPATH}upload.svg`),
  BULK_ICON: require(`${SVGPATH}bulk.svg`),
  DOWNLOAD_ICON: require(`${SVGPATH}download.svg`),
  PROFILE_ICON: require(`${SVGPATH}profile.svg`),
  MAIL_ARROW_ICON: require(`${SVGPATH}mail_arrow.svg`),
  DOCUMENT_ICON: require(`${SVGPATH}document.svg`),
  TRASH: require(`${SVGPATH}trash.svg`),
  ImgPNG: require(`${SVGPATH}icons-PNG.svg`)
};

export { pngs, svgs, docs };
