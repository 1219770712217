import React, { useState } from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { SelectDropDown, Label } from 'pages/ViewSingleRequest/styles';
import {
  AcademicForm,
  ConsentSection,
  RoundedButton,
  AddSection,
  FormFooter
} from '../styles';
import Container from 'components/common/Container';
import { Form, InputContainer } from 'pages/Admin/Clients/styles';
import { svgs } from 'assets';

import { olevelValidationSchema } from 'helpers/validators';
import { Error } from '../../Signin/styles';
import FormUploader from 'components/Uploader';
import Button from 'components/common/Button';
import { CREATE_SOCIAL_MEDIA } from 'graphql/resolvers/admin/candidates/mutations';
import { toastSuccess, toastError } from 'components/common/Toast';
import Loader from 'components/common/Loader';

import { fileDownloader } from './../../../helpers/fileDownloader';

const SocialMedia = ({
  nextTab,
  prevTab,
  index,
  setNewinfo,
  newInfo,
  savedData,
  ...rest
}) => {
  const [showLoader, setLoaderState] = useState(false);
  const [createSocialMediaRequest] = useMutation(CREATE_SOCIAL_MEDIA);

  const handleOnSubmit = async (values) => {
    const payload = [];
    setLoaderState(true);
    for (let i = 0; i < values.socialDetails.length; i++) {
      const response = createSocialMediaRequest({
        variables: {
          verificationRequest: localStorage.getItem('verificationId'),
          id: values.socialDetails?.[i].id ? values.socialDetails?.[i].id : null,
          handle: values.socialDetails?.[i].name,
          url: values.socialDetails?.[i].link
        }
      });
      payload.push(response);
    }
    const results = await Promise.allSettled(payload);

    const dataLoads = [];

    results.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        dataLoads.push(value.data.createSocialMediaRequest.socialMedia);
      }
    });

    const socialDetails = dataLoads?.length
      ? dataLoads.map((social) => ({
          id: social.id,
          name: social.handle,
          link: social.url
        }))
      : [];

    setNewinfo((prev) => ({
      ...prev,
      socialMedia: {
        socialDetails
      }
    }));

    if (dataLoads.length) {
      toastSuccess(svgs.SUCCESS_ICON, 'Social Media Request Created Successfully');
      nextTab(index);
    } else {
      toastError(svgs.DELETE_ICON, 'An error has occured');
    }

    setLoaderState(false);
  };

  return (
    <Formik
      initialValues={{
        socialDetails:
          newInfo?.socialMedia?.socialDetails.length || savedData.length
            ? newInfo?.socialMedia?.socialDetails || savedData
            : [
                { id: '', name: 'LINKEDIN', link: '' },
                { id: '', name: 'FACEBOOK', link: '' },
                { id: '', name: 'TWITTER', link: '' }
              ]
      }}
      validationSchema={olevelValidationSchema}
      onSubmit={handleOnSubmit}
      render={({
        values,
        errors,
        touched,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur
      }) => {
        return (
          <Container>
            <AcademicForm reference>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <FieldArray
                  name="socialDetails"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.socialDetails.length > 0 &&
                        values.socialDetails.map((level, index) => (
                          <div key={index} style={{ padding: '30px' }}>
                            <InputContainer>
                              <label htmlFor="callNumber">Social Media Network</label>
                              <div style={{ marginLeft: '1.2rem' }}>
                                <SelectDropDown
                                  name={`socialDetails.${index}.name`}
                                  width="100%"
                                  onChange={handleChange}
                                  value={values.socialDetails?.[index].name}
                                >
                                  <option>{'Select Social Media Network'}</option>
                                  <option value="LINKEDIN">Linkedin</option>
                                  <option value="FACEBOOK">Facebook </option>
                                  <option value="INSTAGRAM">Instagram</option>
                                  <option value="TWITTER">Twitter</option>
                                </SelectDropDown>
                                {touched?.socialDetails?.[index]?.name &&
                                errors?.socialDetails?.[index]?.name ? (
                                  <Error>{errors?.socialDetails?.[index]?.name}</Error>
                                ) : null}
                              </div>
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="examNumber ">Add Link</label>
                              <input
                                id="link"
                                name={`socialDetails.${index}.link`}
                                type="text"
                                placeholder="Profile Link"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.socialDetails?.[index].link}
                                className={`InputElement ${
                                  touched?.socialDetails?.[index]?.link &&
                                  errors?.socialDetails?.[index]?.link
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {touched?.socialDetails?.[index]?.link &&
                              errors?.socialDetails?.[index]?.link ? (
                                <Error>{errors?.socialDetails?.[index]?.link}</Error>
                              ) : null}
                            </InputContainer>
                            <AddSection>
                              <AddSection>
                                {index > 2 && (
                                  <button
                                    className="text-red"
                                    bgColor="transparent"
                                    textColor="#000"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    REMOVE
                                  </button>
                                )}
                                {index > 1 && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      push({
                                        name: '',
                                        link: ''
                                      });
                                    }}
                                  >
                                    + Add Social Media Network
                                  </button>
                                )}
                              </AddSection>
                            </AddSection>
                          </div>
                        ))}
                    </>
                  )}
                />
                <FormFooter>
                  <Button backbutton type="button" onClick={() => prevTab(index)}>
                    Back
                  </Button>
                  <Button type="submit" disabled={showLoader ? true : false}>
                    {showLoader ? (
                      <div style={{ textAlign: 'center' }}>
                        <Loader />
                      </div>
                    ) : (
                      'Next'
                    )}
                  </Button>
                </FormFooter>
              </Form>
            </AcademicForm>
          </Container>
        );
      }}
    />
  );
};

export default SocialMedia;
