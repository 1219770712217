import gql from 'graphql-tag';

export const GET_VERIFICATOIN_LIST = gql`
  {
    verificationOptionList {
      results {
        id
        name
      }
    }
  }
`;

export const GET_CLIENT_LIST = gql`
  {
    clientList {
      results {
        id
        name
        email
        candidateClient {
          id
          lastname
          firstname
        }
        verificationTypes {
          id
          name
        }
      }
    }
  }
`;

export const GET_SINGLE_CLIENT = gql`
  query client($id: UUID!, $search: String, $batchId: UUID) {
    client(id: $id) {
      id
      name
      createdAt
      verClients {
        id
        candidate(search: $search, batchId: $batchId) {
          id
          firstname
          lastname
          batch {
            id
            name
          }
          createdAt
        }
      }
    }
  }
`;

export const GET_CLIENT_USERS = gql`
  query clientUser($id: UUID!, $limit: Int, $offset: Int) {
    clientUsers(id: $id, limit: $limit, offset: $offset) {
      totalCount
      results {
        client {
          name
        }
        id
        email
        firstname
        lastname
        phone
        dateJoined
      }
    }
  }
`;

export const GET_TIMELINE = gql`
  query timeLine {
    timelineList {
      totalCount
      results {
        action
        createdAt
      }
    }
  }
`;
