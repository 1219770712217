import React, { useState, useEffect, useCallback } from 'react';
import RequestTabs from 'components/RequestTabs';
import { useQuery, useMutation } from '@apollo/client';
import Academic from './Academic';
import Olevel from './Olevel';
import Reference from './Reference';
import Guarantor from './Guarantor';
import Nysc from './Nysc';
import Criminal from './Criminal';
import Credit from './Credit';
import SocialMedia from './SocialMedia';
import Professional from './Professional';
import Address from './Address';
import Submit from './Submit';
import { GET_VERIFICATOIN_LIST } from 'graphql/resolvers/admin/clients/queries';
import { VERIFY_CANDIDATE, GET_CONSENT_DOCS } from 'graphql/queries/candidates/queries';
import { ADD_VERIFY_CANDIDATE } from 'graphql/resolvers/admin/candidates/mutations';
import Loader from 'components/common/Loader';
import { LoaderContainer } from 'components/FullPageLoader/loader';
import Employer from './Employer';
import Navbar from 'components/Navbar';
import Button from 'components/common/Button';
import Container from 'components/common/Container';
import { Form, InputContainer } from '../Admin/Clients/styles';
import { Error } from '../Signin/styles';
import { AcademicForm, AcademicContainer, InfoCard } from './styles';

const mapResultToComponent = {
  Academic: Academic,
  Professional: Professional,
  Credit: Credit,
  Reference: Reference,
  Guarantor: Guarantor,
  Address: Address,
  Criminal: Criminal,
  NYSC: Nysc,
  Employment: Employer,
  OLevel: Olevel,
  SocialMedia: SocialMedia
};

const ValidateCandidate = ({
  tabs,
  activeTab,
  handleTabs,
  authorize,
  handleVerifyCandidate,
  emailError,
  setEmail,
  email,
  showLoader,
  loginErrorMessage
}) => {
  return (
    <>
      {authorize ? (
        <RequestTabs
          tabs={tabs}
          activeTab={activeTab}
          tabNav={false}
          handleTabs={handleTabs}
          tabOnly
        />
      ) : (
        <Container>
          <AcademicForm professional>
            <Form onSubmit={handleVerifyCandidate}>
              <InfoCard>
                <p className="title">Authorization</p>
                <InputContainer>
                  <label htmlFor="">Candidate Email Address</label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Candidate Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {emailError ? <Error>{loginErrorMessage}</Error> : null}
                </InputContainer>

                <Button type="submit">
                  {showLoader ? (
                    <div>
                      <Loader />
                    </div>
                  ) : (
                    'Authorize'
                  )}
                </Button>
              </InfoCard>
            </Form>
          </AcademicForm>
        </Container>
      )}
    </>
  );
};

const Candidate = ({ history, match: { params }, ...props }) => {
  let verfId;
  const [tabs, setTabs] = useState([]);
  const [availableTabs, setAvailableTabs] = useState([]);
  const { loading, data, errors } = useQuery(GET_VERIFICATOIN_LIST);
  const [response, setResponse] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [authorize, setAuthorize] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const handleTabs = (tab) => setActiveTab(tab);
  const [newInfo, setNewinfo] = useState({});

  const { loading: loadindDocs, data: docsList, errors: docsError } = useQuery(
    GET_CONSENT_DOCS
  );

  const checker = availableTabs?.length;
  const incrementTab = useCallback(
    (index) => {
      if (!verfId) {
        index <= checker ? setActiveTab(index + 1) : setActiveTab(index);
      } else {
        index <= JSON.parse(localStorage.getItem('verificationTypes')).length
          ? setActiveTab(index + 1)
          : setActiveTab(index);
      }
    },
    [checker, verfId]
  );

  const decrementTab = (index) => {
    index > 0 ? setActiveTab(index - 1) : setActiveTab(index);
  };

  if (!params?.token) {
    verfId = localStorage.getItem('verfId');
  }

  const token = params?.token || localStorage.getItem('token');
  const {
    loading: loadingCandidates,
    data: candidatesData,
    errors: candidatesError
  } = useQuery(VERIFY_CANDIDATE, {
    variables: {
      token: token
    }
  });
  const [verifyCandidate, { loading: showLoader }] = useMutation(ADD_VERIFY_CANDIDATE);

  const handleVerifyCandidate = async (event) => {
    event.preventDefault();
    setEmailError(false);

    if (!email) {
      setEmailError(true);
    }

    if (email) {
      try {
        const response = await verifyCandidate({
          variables: {
            token: params?.token,
            email: email
          }
        });
        const { ok, errors } = response.data.verifyCandidate;
        if (ok) {
          setAuthorize(true);
        } else {
          setAuthorize(false);
          setEmailError(true);
          setLoginErrorMessage(errors?.messages);
        }
      } catch (err) {
        setAuthorize(false);
        setEmailError(true);
      }
    }
  };

  const fileName = `${candidatesData?.verifyCandidateToken['candidate'].firstname}_${candidatesData?.verifyCandidateToken['candidate'].lastname}`;
  const savedSubmit = candidatesData?.verifyCandidateToken['consentRequest'];

  const savedAcademic = candidatesData?.verifyCandidateToken['acadRequest']?.length
    ? candidatesData?.verifyCandidateToken['acadRequest'].map((acad) => ({
        id: acad.id,
        name: acad.nameOfInstitution,
        degree: acad.qualification,
        major: acad.courseOfStudy,
        grade: acad.grade,
        location: acad.location,
        yearStarted: new Date(acad.yearStarted).getFullYear().toString(),
        yearCompleted: new Date(acad.yearCompleted).getFullYear().toString(),
        cert: acad.upload
      }))
    : [];
  const savedAddress = candidatesData?.verifyCandidateToken['addressRequest']?.length
    ? candidatesData?.verifyCandidateToken['addressRequest'].map((add) => ({
        id: add.id,
        address: add.address,
        contactPerson: add.contactPerson,
        phoneNumber: add.phoneNumber,
        busStop: add.nearestBusStop,
        landmark: add.nearestLandmark,
        city: add.city,
        localGovt: add.localGovernment,
        state: add.state
      }))
    : [];

  const identicationOptions = {
    NIN: 'NIN',
    DRIVERS_LICENCE: 'DRIVERS LICENCE',
    INTL_PASSPORT: ' INTL PASSPORT',
    PERMANENT_VOTERS_CARD: 'PERMANENT VOTERS CARD'
  };
  const savedCredit = candidatesData?.verifyCandidateToken['creditRequest']?.length
    ? candidatesData?.verifyCandidateToken['creditRequest'].map((credit) => ({
        id: credit.id,
        meansOfIdentification: identicationOptions[credit.meansOfIdentification],
        bvn: credit.bvn,
        phoneNumber: credit.phoneNumber,
        dob: credit.dob,
        idNumber: credit.idNumber,
        idFront: credit.idFront,
        idBack: credit.idBack
      }))
    : [];
  const savedCriminal = candidatesData?.verifyCandidateToken['criminalRequest']?.length
    ? candidatesData?.verifyCandidateToken['criminalRequest'].map((crime) => {
        const date = new Date(crime.fingerprintCapturingDate);
        const formattedDate = date.toISOString().slice(0, 10);
        return {
          id: crime.id,
          race: crime.race,
          date: formattedDate,
          venue: crime.fingerprintCapturingVenue,
          passport: crime.passport
        };
      })
    : [];
  const savedEmployment = candidatesData?.verifyCandidateToken['employeeRequest']?.length
    ? candidatesData?.verifyCandidateToken['employeeRequest'].map((emp) => ({
        id: emp.id,
        name: emp.employerName,
        contactPerson: emp.contactPerson,
        contactPersonRole: emp.contactPersonRole,
        email: emp.email,
        address: emp.address,
        phone: emp.phone
      }))
    : [];
  const savedGuarantor = candidatesData?.verifyCandidateToken['guarantorRequest']?.length
    ? candidatesData?.verifyCandidateToken['guarantorRequest'].map((gua) => ({
        id: gua.id,
        guarantorName: gua.name,
        email: gua.email,
        phone: gua.phone,
        address: gua.address,
        organization: gua.organization,
        role: gua.role,
        officialEmail: gua.officialEmail,
        officialPhone: gua.officialPhone,
        officialAddress: gua.officialEmail,
        upload: gua.upload
      }))
    : [];
  const certificateTypeOptions = {
    NYSC_DISCHARGE_CERTIFICATE: 'NYSC DISCHARGE CERTIFICATE',
    NYSC_EXEMPTION_CERTIFICATE: 'NYSC EXEMPTION CERTIFICATE',
    NYSC_EXCLUSION_CERTIFICATE: 'NYSC EXCLUSION CERTIFICATE'
  };

  const savedNYSC = candidatesData?.verifyCandidateToken['nyscRequest']?.length
    ? candidatesData?.verifyCandidateToken['nyscRequest'].map((nysc) => ({
        id: nysc.id,
        callNumber: nysc.callNumber,
        upload: nysc.upload,
        certificateNumber: nysc.certificateNumber,
        dateStarted: nysc.dateStarted,
        dateCompleted: nysc.dateCompleted,
        certificate_type: certificateTypeOptions[nysc.certificateType],
        exemption_grounds: nysc.groundsForExemption
      }))
    : [];
  const savedOLevel = candidatesData?.verifyCandidateToken['olevelRequest']?.length
    ? candidatesData?.verifyCandidateToken['olevelRequest'].map((level) => ({
        id: level.id,
        certificate: level.certificate,
        examName: level.examName,
        examNumber: level.examNumber,
        year: level.year,
        type: level.type
      }))
    : [];
  const savedProfessional = candidatesData?.verifyCandidateToken['profRequest']?.length
    ? candidatesData?.verifyCandidateToken['profRequest'].map((prof) => ({
        id: prof.id,
        awardingBody: prof.awardBody,
        certificateName: prof.certificateName,
        certificateNumber: prof.certificateNumber,
        dateAwarded: prof.dateAwarded,
        upload: prof.upload
      }))
    : [];
  const savedReference = candidatesData?.verifyCandidateToken['refRequest']?.length
    ? candidatesData?.verifyCandidateToken['refRequest'].map((ref) => ({
        id: ref.id,
        referenceName: ref.name,
        email: ref.email,
        officialEmail: ref.officialEmail,
        phone: ref.phone,
        address: ref.address,
        officeAddress: ref.officeAddress
      }))
    : [];

  const savedSocialMedia = candidatesData?.verifyCandidateToken['socialMediaRequest']
    ?.length
    ? candidatesData?.verifyCandidateToken['socialMediaRequest'].map((social) => ({
        id: social.id,
        name: social.handle,
        link: social.url
      }))
    : [];

  const saveDetail = {
    Academic: savedAcademic,
    Address: savedAddress,
    Credit: savedCredit,
    Criminal: savedCriminal,
    Employment: savedEmployment,
    Guarantor: savedGuarantor,
    NYSC: savedNYSC,
    OLevel: savedOLevel,
    Professional: savedProfessional,
    Reference: savedReference,
    SocialMedia: savedSocialMedia
  };

  const displayDetail = {
    Academic: candidatesData?.verifyCandidateToken['acadRequest'],
    Address: candidatesData?.verifyCandidateToken['addressRequest'],
    Credit: candidatesData?.verifyCandidateToken['creditRequest'],
    Criminal: candidatesData?.verifyCandidateToken['criminalRequest'],
    Employment: candidatesData?.verifyCandidateToken['employeeRequest'],
    Guarantor: candidatesData?.verifyCandidateToken['guarantorRequest'],
    NYSC: candidatesData?.verifyCandidateToken['nyscRequest'],
    OLevel: candidatesData?.verifyCandidateToken['olevelRequest'],
    Professional: candidatesData?.verifyCandidateToken['profRequest'],
    Reference: candidatesData?.verifyCandidateToken['refRequest'],
    SocialMedia: candidatesData?.verifyCandidateToken['socialMediaRequest']
  };

  const RenderComponent = useCallback(
    (component, props) => {
      let ComponentName = mapResultToComponent[component];
      const getDocUrl = (name) => {
        const consentDoc = docsList?.documentList?.results.filter((doc) => {
          return doc.name === `${name.toUpperCase()}_CONSENT`;
        });

        if (consentDoc) return consentDoc;
        else {
          return [];
        }
      };
      const firstArrayChecker = saveDetail[component];
      const secondArrayChecker = displayDetail[component]?.filter(
        (req) => req.status === 'PENDING'
      );

      return (
        <>
          {(firstArrayChecker?.length === 0 ||
            (firstArrayChecker?.length > 0 && secondArrayChecker?.length > 0)) && (
            <ComponentName
              nextTab={incrementTab}
              newInfo={newInfo}
              setNewinfo={setNewinfo}
              prevTab={decrementTab}
              index={activeTab}
              fileName={fileName}
              consentDoc={getDocUrl(component)}
              savedData={saveDetail[component]}
            />
          )}
        </>
      );
    },
    [activeTab, docsList, incrementTab, newInfo] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderSubmit = useCallback(
    (component, props) => {
      const closureComponent = { submit: Submit };
      let ComponentName = closureComponent[component];
      const closing = savedSubmit?.length ? savedSubmit : [];

      return (
        <ComponentName
          index={activeTab}
          file={closing.length ? closing[closing.length - 1].file : ''}
          fileName={fileName}
          token={params?.token}
        />
      );
    },
    [savedSubmit, activeTab, fileName, params]
  );

  useEffect(() => {
    if (!loading && !loadingCandidates) {
      setResponse(data?.verificationOptionList?.results);
      setAvailableTabs(candidatesData?.verifyCandidateToken?.requestTypes);
      if (!verfId) {
        localStorage.setItem('verificationId', candidatesData?.verifyCandidateToken?.id);
      } else {
        const verificationId = JSON.parse(verfId);
        localStorage.setItem('verificationId', verificationId.id);
      }

      let newArray =
        response &&
        response.filter((tabItem) => {
          return availableTabs?.includes(tabItem.id) ? tabItem : null;
        });

      if (verfId) {
        let items = JSON.parse(localStorage.getItem('verificationTypes'));
        newArray = response?.filter((tabItem) => {
          return items.includes(tabItem.id) ? tabItem : null;
        });
      }
      newArray = newArray?.filter((item) => {
        const firstCheck = saveDetail[item.name];
        const secondCheck = displayDetail[item.name]?.filter(
          (req) => req.status === 'PENDING'
        );

        return firstCheck?.length === 0 ||
          (firstCheck?.length > 0 && secondCheck?.length > 0)
          ? item
          : null;
      });

      newArray = newArray?.map((item) => {
        return {
          id: item.id,
          name: item.name === 'Employment' ? 'Previous Employer' : item.name,
          component: RenderComponent(item.name)
        };
      });

      // if (newArray?.length >= 1) {
      newArray.push({
        id: 1,
        name: 'Submit',
        component: RenderSubmit('submit')
      });
      // }
      setTabs(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    candidatesData,
    loading,
    loadingCandidates,
    response,
    availableTabs,
    RenderComponent,
    RenderSubmit,
    verfId
  ]);

  // useEffect(
  //   () => {
  //     const allRequest = data?.verificationOptionList?.results;
  //     const processingRequest =
  //       allRequest &&
  //       allRequest.filter((tabItem) => {
  //         return candidatesData?.verifyCandidateToken?.requestTypes?.includes(tabItem.id)
  //           ? tabItem
  //           : null;
  //       });
  //     for (let i = 0; i < processingRequest?.length; i++) {
  //       const element = displayDetail[processingRequest[i].name];

  //       if (candidatesData?.verifyCandidateToken[element]?.length === 0) {
  //         setActiveTab(i);
  //         break; // Exit the loop
  //       } else {
  //         setActiveTab(processingRequest?.length);
  //       }
  //     }
  //   },
  //   [candidatesData] // eslint-disable-line react-hooks/exhaustive-deps
  // );

  if (!verfId) {
    return loadingCandidates && loading ? (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    ) : response && tabs.length > 0 ? (
      <ValidateCandidate
        tabs={tabs}
        activeTab={activeTab}
        handleTabs={handleTabs}
        authorize={authorize}
        handleVerifyCandidate={handleVerifyCandidate}
        emailError={emailError}
        setEmail={setEmail}
        email={email}
        showLoader={showLoader}
        loginErrorMessage={loginErrorMessage}
      />
    ) : (
      <LoaderContainer>
        <Form>
          <div style={{ padding: '30px' }}>
            <InfoCard>
              <p>Thanks for taking your time.</p>

              <p>Your session has expired or does not exist</p>
            </InfoCard>
          </div>
        </Form>
      </LoaderContainer>
    );
  } else {
    return loadingCandidates && loading ? (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    ) : (
      <Navbar>
        <RequestTabs
          tabs={tabs}
          activeTab={activeTab}
          tabNav={false}
          handleTabs={handleTabs}
          tabOnly
        />
      </Navbar>
    );
  }
};

export default Candidate;
