import { InMemoryCache } from 'apollo-cache-inmemory';
import { isLoggedIn } from '../helpers/utils';
import { authUserInitialData } from '../graphql/resolvers/auth';
export const cache = new InMemoryCache({
  freezeResults: true,
});

cache.writeData({
  data: {
    isLoggedIn: isLoggedIn(),
    AuthUser: authUserInitialData,
  },
});
