import { gql } from '@apollo/client';
import { GET_AUTH_USER_QUERY } from './queries';

export const setAuthUserMutation = (_root, variables, { cache }) => {
  const result = cache.readQuery({
    query: GET_AUTH_USER_QUERY,
  });

  const data = {
    AuthUser: {
      ...result.AuthUser,
      ...variables.user,
      isAuthenticated: true,
    },
  };

  localStorage.setItem('token', variables.token);
  localStorage.setItem('user', JSON.stringify(variables.user));
  cache.writeData({ data });

  return data;
};

export const removeAuthUserMutation = (_root, _, { cache }) => {
  const data = {
    AuthUser: {
      id: '',
      email: '',
      firstname: '',
      lastname: '',
      image: '',
      phone: '',
      roles: [],
      client: {
        id: '',
        __typename: 'ClientUser',
      },
      isAuthenticated: false,
      __typename: 'AuthUser',
    },
  };
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  cache.writeData({ data });
  return data;
};

export const SET_AUTH_USER_MUTATION = gql`
  mutation SetAuthUser($user: AuthUser!, $token: String!) {
    setAuthUserMutation(user: $user, token: $token) @client
  }
`;

export const REMOVE_AUTH_USER_MUTATION = gql`
  mutation removeAuthUser($user: AuthUser!, $token: String!) {
    removeAuthUserMutation(user: $user, token: $token) @client
  }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      payload
      token
      user {
        id
        firstname
        lastname
        image
        email
        phone
        roles
        client {
          id
        }
      }

      refreshExpiresIn
    }
  }
`;

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
      success
      errors {
        messages
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(resetDetails: { newPassword: $newPassword, token: $token }) {
      ok
      success
      errors {
        messages
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation userVerifyEmail($email: String!, $role: SignupRoleEnum) {
    userVerifyEmail(email: $email, role: $role) {
      ok
      success {
        messages
      }
      errors {
        messages
      }
    }
  }
`;
