// react libraries
import * as React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import Signin from '../pages/Signin';
import ForgotPassword from '../pages/ForgotPassword';
import SetPassword from '../pages/SetPassword';
import CreatePassword from '../pages/CreatePassword';
import Candidates from '../pages/Candidate';
import ProvideInfo from '../pages/Candidate/ProvideInfo';
import AuthenticatedRoute from 'components/common/AuthenticatedRoute';
import { useAuth } from 'context/authContext';
import SuccessPage from 'pages/SuccessPage';

const AdminDashBoard = React.lazy(() => import('../pages/Admin/Home'));
const Clients = React.lazy(() => import('../pages/Admin/Clients'));
const ClientRequest = React.lazy(() => import('../pages/Admin/Clients/Requests'));
const ClientUsers = React.lazy(() => import('../pages/Admin/Clients/Users'));
const AdminClientCandidates = React.lazy(() =>
  import('../pages/Admin/Clients/ClientCandidates')
);
const AdminClientDocuments = React.lazy(() =>
  import('../pages/Admin/Clients/ClientDocuments')
);
const ClientCandidates = React.lazy(() => import('../pages/Client/Candidates'));
const InitiateCandidateRequest = React.lazy(() =>
  import('../pages/Client/Candidates/InitiateRequest')
);
const AdminSettings = React.lazy(() => import('../pages/Admin/Settings'));
const Reports = React.lazy(() => import('../pages/Reports'));
const Documents = React.lazy(() => import('../pages/Documents'));
const ViewSingleRequest = React.lazy(() => import('../pages/ViewSingleRequest'));
const AdminRequests = React.lazy(() => import('../pages/Admin/Requests'));
const Bulk = React.lazy(() => import('../pages/Bulk'));
const VerificationOptions = React.lazy(() =>
  import('../pages/Client/VerificationOptions')
);
const ClientRequests = React.lazy(() => import('pages/Client/Requests'));
const AdminClientRequests = React.lazy(() =>
  import('pages/Client/Requests/AdminClientRequests')
);

const Routes = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <Route exact path="/signin" component={Signin} />

      <AuthenticatedRoute
        path="/dashboard"
        role={user?.roles[0]}
        component={AdminDashBoard}
        action={['dashboard:view']}
      />
      <AuthenticatedRoute
        path="/admin/reports"
        role={user?.roles[0]}
        component={Reports}
        action={['report:view']}
      />
      <AuthenticatedRoute
        path="/admin/documents"
        role={user?.roles[0]}
        component={Documents}
        action={['report:view']}
      />
      <AuthenticatedRoute
        path="/admin/bulk/academic"
        role={user?.roles[0]}
        component={Bulk}
        action={['bulk-academic:view']}
      />
      <AuthenticatedRoute
        path="/admin/clients/users/:clientId"
        role={user?.roles[0]}
        component={ClientUsers}
        action={['client-users:view']}
      />
      <AuthenticatedRoute
        path="/client_candidates/:clientId"
        role={user?.roles[0]}
        component={AdminClientCandidates}
        action={['client-users:view']}
      />
      <AuthenticatedRoute
        path="/client_documents/:clientId"
        role={user?.roles[0]}
        component={AdminClientDocuments}
        action={['client-users:view']}
      />
      <AuthenticatedRoute
        path="/admin/clients/view/:clientId"
        role={user?.roles[0]}
        component={ClientRequest}
        action={['clients:view']}
      />
      <AuthenticatedRoute
        path="/admin/clients"
        role={user?.roles[0]}
        component={Clients}
        action={['admin-client:view']}
      />
      <AuthenticatedRoute
        path="/admin/settings"
        role={user?.roles[0]}
        component={AdminSettings}
        action={['admin-settings:view']}
      />
      <AuthenticatedRoute
        path="/requests/view/:requestId"
        role={user?.roles[0]}
        component={ViewSingleRequest}
        action={['single-request:view']}
      />
      <AuthenticatedRoute
        path="/admin/requests"
        role={user?.roles[0]}
        component={AdminRequests}
        action={['requests:view']}
      />
      <AuthenticatedRoute
        path="/admin_client/requests/:clientId"
        role={user?.roles[0]}
        component={AdminClientRequests}
        action={['requests:view']}
      />
      <AuthenticatedRoute
        path="/client/reports"
        role={user?.roles[0]}
        component={Reports}
        action={['report:view']}
      />
      <AuthenticatedRoute
        path="/client/candidates"
        role={user?.roles[0]}
        component={ClientCandidates}
        action={['client-candidates:view']}
      />
      <AuthenticatedRoute
        path="/client/initiate/:candidateId"
        role={user?.roles[0]}
        component={InitiateCandidateRequest}
        action={['candidates-of-client:view']}
      />
      <AuthenticatedRoute
        path="/client/reinitiate/:candidateId"
        role={user?.roles[0]}
        component={InitiateCandidateRequest}
        action={['candidates-of-client:view']}
      />
      <AuthenticatedRoute
        path="/candidate-provide-info/:verifyId"
        role={user?.roles[0]}
        component={ProvideInfo}
        action={['candidates-of-client:view']}
      />
      <AuthenticatedRoute
        path="/requests/view/:requestId"
        role={user?.roles[0]}
        component={ViewSingleRequest}
        action={['single-request:view']}
      />
      {/* <AuthenticatedRoute
        path="/candidates"
        role={user?.roles[0]}
        component={Candidates}
        action={['candidates:view']}
      /> */}
      <AuthenticatedRoute
        path="/client/verification-options"
        role={user?.roles[0]}
        component={VerificationOptions}
        action={['verification:view']}
      />
      <AuthenticatedRoute
        path="/client/requests"
        role={user?.roles[0]}
        component={ClientRequests}
        action={['client-requests:view']}
      />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/create-password" component={CreatePassword} />
      <Route path="/reset-password" component={SetPassword} />
      <Route path="/success" component={SuccessPage} />
      <Route path="/candidate/:token" component={Candidates} />
      <Route path="/candidate" component={Candidates} />

      <Redirect to="/signin" />
    </Switch>
  );
};

export default Routes;
