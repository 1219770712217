import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { resetPasswordValidationSchema } from '../../helpers/validators';
import Button from '../../components/common/Button';
import AuthBackground from 'components/common/AuthBackground';
import FormCard from 'components/common/FormCard';
import { RESET_PASSWORD } from 'graphql/resolvers/auth/mutations';
import { svgs } from 'assets';
import {
  LoginLink,
  FormCardTitle,
  Form,
  InputContainer,
  Error,
  Message,
  Password,
  Success
} from './setPassword';
import Loader from 'components/common/Loader';
import { Close, Warning } from 'pages/Signin/styles';

const SetPassword = ({ history, match }) => {
  const token = history.location.search.split('?token=')[1];

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordIcon = showPassword ? (
    <svgs.EYE style={{ width: '2rem', height: '2rem' }} />
  ) : (
    <svgs.HIDE_EYE style={{ width: '2rem', height: '2rem' }} />
  );

  const [resetPassword, { data, error, loading }] = useMutation(RESET_PASSWORD);

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      ...resetPasswordValidationSchema
    }),
    onSubmit: async (values) => {
      let newPassword = values.password;
      try {
        await resetPassword({ variables: { newPassword, token } });
        history.push('/success', {
          isValid: true,
          message: 'Password reset successful'
        });
      } catch (err) {
        console.log('A problem occurred, please try again.');
      }
    }
  });

  return (
    <AuthBackground>
      <FormCard type="reset">
        <svgs.LOGO className="logo" />
        <FormCardTitle>
          <h3>Set new password</h3>
          <p>Create a new password for your account</p>
        </FormCardTitle>
        {loading ? (
          <Loader fullpage={true} small={true} />
        ) : error && error.graphQLErrors[0].message ? (
          <Message>
            <Warning />
            {error.graphQLErrors[0].message}
            <Close />
          </Message>
        ) : data && data.success ? (
          <Success>{data.success}</Success>
        ) : (
          ''
        )}
        <Form onSubmit={handleSubmit}>
          <InputContainer>
            <label htmlFor="password">password</label>
            <input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={`InputElement ${
                touched.password && errors.password ? 'Invalid' : ''
              }`}
            />
            <Password onClick={togglePassword}>{passwordIcon}</Password>
            {touched.password && errors.password ? (
              <Error>
                <Warning />
                {errors.password}
              </Error>
            ) : null}
          </InputContainer>
          <span></span>
          <Button type="submit">Login</Button>
        </Form>
        <LoginLink href="/signin">Back to Login</LoginLink>
      </FormCard>
    </AuthBackground>
  );
};

export default SetPassword;
