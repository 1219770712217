import React from 'react';

const Layout = ({ icon, message }) => (
  <div>
    <img src={icon} alt="icon" />
    &nbsp;&nbsp;{message}
  </div>
);

export default Layout;
