import styled from 'styled-components';
import { svgs } from 'assets/';

export const AuthContainer = styled.div`
  background-size: cover;
  background-position: center;
  background-image: url(${svgs.LOGIN_BACKGROUND});
  background-repeat: no-repeat;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(38.75rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(35rem, 1fr));
  align-items: center;
  justify-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
`;
export const Info = styled.div`
  grid-column: 1/-1;
  grid-row: 2/-1;
  display: grid;
  justify-content: center;
  align-content: space-between;
  position: relative;
`;
export const Copyright = styled.div`
  margin-top: 120px;
  color: #fff;
  font-weight: 200;
  font-size: 1.3rem;
  text-align: center;
  bottom: 2%;
  justify-self: center;
  align-self: start;
  width: 100%;
`;
