import React from 'react';

import { AuthContainer, Info, Copyright } from './auth';

const AuthBackground = ({ children }) => {
  return (
    <AuthContainer>
      {children}
      <Info>
        <Copyright>
          Copyright 2020 . Workforce Group . All rights reserved . Powered by
          ZTP
        </Copyright>
      </Info>
    </AuthContainer>
  );
};

export default AuthBackground;
