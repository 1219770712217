import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './authContext';
import { AdminNavIdProvider } from './adminNavIdContext';
import { client } from '../graphql';

const AppProvider = ({ children }) => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <AdminNavIdProvider>{children}</AdminNavIdProvider>
    </AuthProvider>
  </ApolloProvider>
);

export default AppProvider;
