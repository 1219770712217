import styled from 'styled-components';

export const MainContainer = styled.div`
  background: #e5e5e5;
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-direction: column;
`;

export const NavBar = styled.div`
  display: grid;
  padding: 30px 0;
  grid-template-columns: 1fr 1fr;
  div {
    flex: 1;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  background: #e5e5e5;
`;
