import React from 'react';
import { LoaderContainer } from './loader.js';

const Loader = ({ fullPage, small }) => (
  <LoaderContainer fullPage={fullPage}>
    <div className="loader"></div>
  </LoaderContainer>
);

export default Loader;
