import decodeJWT from 'jwt-decode';
import { setAuthUserMutation, removeAuthUserMutation, RESET_PASSWORD } from './mutations';
import { getAuthUserQuery } from './queries';

const getUserFromToken = () => {
  const { token } = localStorage;

  const data = {
    isAuthenticated: false
  };

  if (token) {
    const decodedToken = decodeJWT(token);

    const { iss, exp, origIat, ...user } = decodedToken;

    const currentTime = Date.now() / 1000;

    if (exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return data;
    }

    const localUserData = JSON.parse(localStorage.getItem('user'));

    return {
      ...data,
      ...user,
      ...localUserData,
      isAuthenticated: true
    };
  }

  return data;
};

export const authUserInitialData = {
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  image: '',
  phone: '',
  roles: [],
  client: {
    id: '',
    __typename: 'ClientUser'
  },
  isAuthenticated: false,
  __typename: 'AuthUser',
  ...getUserFromToken()
};

export const authMutations = {
  setAuthUserMutation,
  removeAuthUserMutation,
  RESET_PASSWORD
};

export const authQueries = {
  getAuthUserQuery
};
