import React, { useState, useCallback } from 'react';
import { Section, Uploader, UploadButtonContainer } from './styles';
import { useDropzone } from 'react-dropzone';
import { svgs } from 'assets/';
import Button from 'components/common/Button';
import { toastError } from 'components/common/Toast';
import Loader from 'components/common/Loader';

const Upload = ({
  handleUpload,
  accept,
  form = false,
  text = 'Upload your filled template',
  loading = false,
  buttonText = 'Upload File',
  file = '',
  ...props
}) => {
  const [uploadedFile, setUploadedFile] = useState(null || file);
  const [isValid, setIsValid] = useState(false);

  const getLastSectionFromUrl = (url) => {
    const regex = /\/([^\/]+)$/; // Match the last section after the last slash
    const match = url.match(regex);

    return match ? match[1] : url; // Return the matched section or the original URL if no match
  };

  const onDrop = useCallback(
    (acceptedFile) => {
      console.log(acceptedFile[0].type);
      if (!accept.includes(acceptedFile[0].type)) {
        setIsValid(false);
        toastError('', 'Please upload a valid File');
      } else {
        setIsValid(true);
        setUploadedFile(acceptedFile);
      }
      if (form) handleUpload(acceptedFile);
    },
    [accept, form, handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Section form={form} {...props}>
      <Uploader {...getRootProps()} form={form}>
        {form ? (
          <>
            <div>
              <input {...getInputProps()} />
              <img src={svgs.UPLOAD_ICON} alt="" />
            </div>
            <div>
              <p>{text}</p>
              <p>
                <br />

                <>
                  {typeof uploadedFile === 'string'
                    ? `File uploaded: ${getLastSectionFromUrl(uploadedFile)}`
                    : ''}
                </>

                {typeof uploadedFile !== 'string' && uploadedFile
                  ? `File uploaded: ${
                      file && !uploadedFile[0] ? uploadedFile.name : uploadedFile[0].name
                    }`
                  : ''}
              </p>

              <label>
                Drop the files here or <span>Browse</span>
              </label>
            </div>
          </>
        ) : (
          <>
            <img src={svgs.UPLOAD_ICON} alt="" />
            <p>{text}</p>
            <input {...getInputProps()} />
            {isDragActive ? (
              <label>
                Drop the files here <img src={svgs.ARROW_DOWN} alt="download" />
              </label>
            ) : !uploadedFile ? (
              <label>
                Drop file here or <span>Browse</span>
              </label>
            ) : (
              <label>{uploadedFile[0].name}</label>
            )}
          </>
        )}
      </Uploader>
      {form ? null : (
        <UploadButtonContainer>
          <Button disabled={!isValid} onClick={() => handleUpload(uploadedFile)}>
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Loader />
              </div>
            ) : (
              buttonText
            )}
          </Button>
        </UploadButtonContainer>
      )}
    </Section>
  );
};

export default Upload;
