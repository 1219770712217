import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_AUTH_USER_QUERY } from '../graphql/resolvers/auth/queries';
import {
  LOGIN_USER,
  SET_AUTH_USER_MUTATION,
  REMOVE_AUTH_USER_MUTATION
} from '../graphql/resolvers/auth/mutations';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const { data } = useQuery(GET_AUTH_USER_QUERY, { fetchPolicy: 'cache-only' });
  const [setAuthUser] = useMutation(SET_AUTH_USER_MUTATION);
  const [currentClient, setCurrentClient] = useState(null);
  const [removeAuthUser] = useMutation(REMOVE_AUTH_USER_MUTATION);
  const [loginUser, { error: loginErrors, loading }] = useMutation(LOGIN_USER);

  return (
    <AuthContext.Provider
      value={{
        user: data?.AuthUser,
        loginUser,
        setAuthUser,
        loginErrors,
        loading,
        removeAuthUser,
        currentClient,
        setCurrentClient
      }}
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
