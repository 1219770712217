import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

import { CREATE_CRIMINAL } from 'graphql/resolvers/admin/candidates/mutations';
import * as Yup from 'yup';

import { InputContainer, Form } from '../../Admin/Clients/styles';
import { AcademicForm, FormFooter } from '../styles';
import { Error } from 'pages/Signin/styles';
import { toastSuccess, toastError } from 'components/common/Toast';
import { criminalValidationSchema } from 'helpers/validators';
import { renameFileUpload } from 'utils/renameUploadUtils';

import Container from 'components/common/Container';
import { Select } from './style';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import FormUploader from 'components/Uploader';
import { svgs } from 'assets';
import { selectOptions } from './dropdownData';

const Criminal = ({
  nextTab,
  prevTab,
  index,
  newInfo,
  setNewinfo,
  savedData,
  fileName,
  ...props
}) => {
  const checkFile = (file) => {
    if (file) {
      return file;
    } else {
      return null;
    }
  };

  const [showLoader, setLoaderState] = useState(null);
  const [showpassport, setShowPassport] = useState(
    savedData[savedData.length - 1]?.passport || checkFile(newInfo?.criminal?.passport)
  );
  const [showPassportError, setShowPassportError] = useState(false);

  const [selectRace, setSelectRace] = useState(selectOptions[0]);

  const [createCriminalRequest] = useMutation(CREATE_CRIMINAL);
  const handlePassport = (files) => {
    const modifiedFile = files[0];
    // const modifiedFile = renameFileUpload(file, fileName || 'passport');
    setShowPassport(modifiedFile);
  };
  const handleSelectChange = (e) => {
    setSelectRace(e.target.value);
  };

  const handleClick = () => {
    formik.setFieldValue('race', selectRace);
  };

  const formik = useFormik({
    initialValues: {
      race: selectOptions[0],
      id: '',
      date: '',
      venue: '',
      passport: '',
      ...savedData[savedData.length - 1],
      ...newInfo?.criminal
    },
    validationSchema: Yup.object({
      ...criminalValidationSchema
    }),

    onSubmit: async (values) => {
      setShowPassportError(false);
      setLoaderState(true);

      if (!showpassport) setShowPassportError(true);

      if (showpassport) {
        try {
          const response = await createCriminalRequest({
            variables: {
              verificationRequest: localStorage.getItem('verificationId'),
              id: values.id ? values.id : null,
              race: values.race,
              fingerprintCapturingDate: values.date,
              fingerprintCapturingVenue: values.venue,
              passport: !showpassport ? values?.passport : showpassport
            }
          });
          const { ok, errors } = response.data.createCriminalRequest;
          setLoaderState(false);
          if (errors) {
            const { messages } = errors;
            return toastError('', messages);
          }
          if (!errors && ok) {
            const formValues = {
              ...values,
              passport: showpassport
            };
            setNewinfo((prev) => ({
              ...prev,
              criminal: formValues
            }));
            toastSuccess(svgs.SUCCESS_ICON, 'Criminal Request Submitted Successfully');
            nextTab(index);
          }
        } catch (error) {
          const { message } = error;
          toastError(svgs.DELETE_ICON, message);
        }
      }
      return setLoaderState(false);
    }
  });

  return (
    <Container>
      <AcademicForm reference>
        <Form onSubmit={formik.handleSubmit}>
          <div style={{ padding: '30px' }}>
            <InputContainer>
              <label htmlFor="date">race</label>
              <Select
                value={formik.values.race}
                onChange={handleSelectChange}
                onClick={handleClick}
                id="race"
                name="race"
              >
                {selectOptions?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <label htmlFor="date">Date for data capture</label>
              <input
                id="date"
                name="date"
                type="date"
                placeholder="YYYY-MM-DD"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date}
                className={`InputElement ${
                  formik.touched.date && formik.errors.date ? 'Invalid' : ''
                }`}
              />
              {formik.touched.date && formik.errors.date ? (
                <Error>{formik.errors.date}</Error>
              ) : null}
            </InputContainer>
            <InputContainer>
              <label htmlFor="venue">venue for data capture</label>
              <input
                id="venue"
                name="venue"
                type="text"
                placeholder="Venue for data capture"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.venue}
                className={`InputElement ${
                  formik.touched.venue && formik.errors.venue ? 'Invalid' : ''
                }`}
              />
              {formik.touched.venue && formik.errors.venue ? (
                <Error>{formik.errors.venue}</Error>
              ) : null}
            </InputContainer>
            <InputContainer>
              <label htmlFor="">upload passport</label>
              <FormUploader
                form
                name="passport"
                className={showPassportError ? 'Invalid' : ''}
                accept={[
                  'application/pdf',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'image/png',
                  'image/jpg',
                  'image/jpeg'
                ]}
                handleUpload={handlePassport}
                text="Upload File"
                file={formik.values?.passport}
              />
              {showPassportError ? <Error>Please upload passport</Error> : null}
            </InputContainer>
          </div>

          <FormFooter>
            <Button backbutton type="button" onClick={() => prevTab(index)}>
              Back
            </Button>
            <Button type="submit" disabled={showLoader ? true : false}>
              {showLoader ? (
                <div style={{ textAlign: 'center' }}>
                  <Loader />
                </div>
              ) : (
                'Next'
              )}
            </Button>
          </FormFooter>
        </Form>
      </AcademicForm>
    </Container>
  );
};

export default Criminal;
