/**
 * @name checkPermissions
 * This funtion maps through access rules
 * and grants permissions
 *
 * @param accessRules access rules
 * @param role loggedin user role
 * @param action action available to user
 * @param data TBD
 *
 * @returns {Boolean}
 */
export const checkPermissions = (accessRules, role, action, data) => {
  const priviledges = accessRules[role];
  if (!priviledges) return false;

  const permissions = priviledges.permissions;
  if (permissions && permissions.includes(action[0])) {
    return true;
  }
  return false;
};
