import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { AcademicForm, ConsentSection, RoundedButton, AddSection } from '../styles';
import { renameFileUpload } from 'utils/renameUploadUtils';
import Container from 'components/common/Container';
import { Form, InputContainer } from 'pages/Admin/Clients/styles';
import { SelectDropDown } from 'pages/ViewSingleRequest/styles';

import { svgs } from 'assets';

import { academicValidationSchema } from 'helpers/validators';
import { Error } from '../../Signin/styles';
import FormUploader from 'components/Uploader';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { ADD_ACADEMIC_VERIFICATION } from 'graphql/resolvers/admin/candidates/mutations';
import { toastSuccess, toastError } from 'components/common/Toast';
import { GET_INSTITUTION_LIST } from '../../../graphql/resolvers/client/queries';
import { VERIFY_CANDIDATE } from 'graphql/queries/candidates/queries';

import { fileDownloader } from './../../../helpers/fileDownloader';

const Academic = ({
  nextTab,
  index,
  setNewinfo,
  newInfo,
  savedData,
  fileName,
  ...rest
}) => {
  const [showLoader, setLoaderState] = useState(false);
  const [createAcademicRequest] = useMutation(ADD_ACADEMIC_VERIFICATION);
  const { data } = useQuery(GET_INSTITUTION_LIST);

  const handleOnSubmit = async (values) => {
    setLoaderState(true);
    const payload = [];
    for (let i = 0; i < values.educationDetails.length; i++) {
      const response = createAcademicRequest({
        variables: {
          vId: localStorage.getItem('verificationId'),
          id: values.educationDetails?.[i].id ? values.educationDetails?.[i].id : null,
          name: values.educationDetails?.[i].name,
          qualification: values.educationDetails?.[i].degree,
          course: values.educationDetails?.[i].major,
          grade: values.educationDetails?.[i].grade,
          location: values.educationDetails?.[i].location,
          yearStarted: values.educationDetails?.[i].yearStarted,
          yearCompleted: values.educationDetails?.[i].yearCompleted,
          cert: values.educationDetails?.[i].cert
        }
      });
      payload.push(response);
    }

    Promise.allSettled(payload).then((result) => console.log(result));
    const results = await Promise.allSettled(payload);

    const payloads = [];

    results.forEach(({ status, value, reason }) => {
      if (status === 'fulfilled') {
        payloads.push(value.data.createAcademicRequest.academic);
      }
    });

    const educationDetails = payloads?.length
      ? payloads.map((acad) => ({
          id: acad.id,
          name: acad.nameOfInstitution,
          degree: acad.qualification,
          major: acad.courseOfStudy,
          grade: acad.grade,
          location: acad.location,
          yearStarted: new Date(acad.yearStarted).getFullYear().toString(),
          yearCompleted: new Date(acad.yearCompleted).getFullYear().toString(),
          cert: acad.upload
        }))
      : [];

    setNewinfo((prev) => ({
      ...prev,
      academic: {
        educationDetails
      }
    }));

    if (payloads.length) {
      toastSuccess(svgs.SUCCESS_ICON, 'Academic Request Created Successfully');
      nextTab(index);
    } else {
      toastError(svgs.DELETE_ICON, 'An error has occured');
    }

    setLoaderState(false);
  };

  return (
    <Formik
      initialValues={{
        educationDetails:
          newInfo?.academic?.educationDetails?.length || savedData?.length
            ? newInfo?.academic?.educationDetails || savedData
            : [
                {
                  id: '',
                  name: '',
                  degree: '',
                  major: '',
                  grade: '',
                  location: '',
                  yearStarted: '',
                  yearCompleted: '',
                  cert: ''
                  // ...newInfo?.academic
                }
              ]
      }}
      validationSchema={academicValidationSchema}
      onSubmit={handleOnSubmit}
      render={({
        values,
        errors,
        touched,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur
      }) => {
        return (
          <Container>
            <AcademicForm professional>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <FieldArray
                  name="educationDetails"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.educationDetails.length > 0 &&
                        values.educationDetails.map((education, index) => (
                          <div key={index} style={{ padding: '30px' }}>
                            <InputContainer>
                              <label htmlFor="name">NAME OF INSTITUTION</label>
                              <div style={{ marginLeft: '0.2rem' }}>
                                <SelectDropDown
                                  name={`educationDetails.${index}.name`}
                                  width="100%"
                                  onChange={handleChange}
                                  value={values.educationDetails?.[index].name}
                                >
                                  <option>{'Select University'}</option>
                                  {data?.institutionList?.results.map((data, index) => (
                                    <option
                                      key={`${data.nameOfInstitution}_${index}`}
                                      value={data.nameOfInstitution}
                                    >
                                      {data.nameOfInstitution}
                                    </option>
                                  ))}
                                </SelectDropDown>
                              </div>
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].name &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].name && (
                                  <Error>{errors.educationDetails[index].name}</Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label>Qualification</label>
                              <input
                                name={`educationDetails.${index}.degree`}
                                placeholder="Qualification"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.educationDetails?.[index].degree}
                                type="text"
                                className={`InputElement ${
                                  touched?.educationDetails?.[index]?.degree &&
                                  errors?.educationDetails?.[index]?.degree
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].degree &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].degree && (
                                  <Error>{errors.educationDetails[index].degree}</Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label>Course of Study</label>
                              <input
                                name={`educationDetails.${index}.major`}
                                placeholder="Course of Study"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.educationDetails?.[index].major}
                                type="text"
                                className={`InputElement ${
                                  touched?.educationDetails?.[index]?.major &&
                                  errors?.educationDetails?.[index]?.major
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].major &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].major && (
                                  <Error>{errors.educationDetails[index].major}</Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label>Grade</label>
                              <input
                                name={`educationDetails.${index}.grade`}
                                placeholder="Grade"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.educationDetails?.[index].grade}
                                type="text"
                                className={`InputElement ${
                                  touched?.educationDetails?.[index]?.grade &&
                                  errors?.educationDetails?.[index]?.grade
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].grade &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].grade && (
                                  <Error>{errors.educationDetails[index].grade}</Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label>Location</label>
                              <input
                                name={`educationDetails.${index}.location`}
                                placeholder="Location"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.educationDetails?.[index].location}
                                type="text"
                                className={`InputElement ${
                                  touched?.educationDetails?.[index]?.location &&
                                  errors?.educationDetails?.[index]?.location
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].location &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].location && (
                                  <Error>{errors.educationDetails[index].location}</Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label>Year Started</label>
                              <input
                                name={`educationDetails.${index}.yearStarted`}
                                placeholder="Year Started"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.educationDetails?.[index].yearStarted}
                                type="text"
                                className={`InputElement ${
                                  touched?.educationDetails?.[index]?.yearStarted &&
                                  errors?.educationDetails?.[index]?.yearStarted
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].yearStarted &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].yearStarted && (
                                  <Error>
                                    {errors.educationDetails[index].yearStarted}
                                  </Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label>Year Completed</label>
                              <input
                                name={`educationDetails.${index}.yearCompleted`}
                                placeholder="Year Completed"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.educationDetails?.[index].yearCompleted}
                                className={`InputElement ${
                                  touched?.educationDetails?.[index]?.yearCompleted &&
                                  errors?.educationDetails?.[index]?.yearCompleted
                                    ? 'Invalid'
                                    : ''
                                }`}
                              />
                              {errors &&
                                errors.educationDetails &&
                                errors.educationDetails[index] &&
                                errors.educationDetails[index].yearCompleted &&
                                touched &&
                                touched.educationDetails &&
                                touched.educationDetails[index] &&
                                touched.educationDetails[index].yearCompleted && (
                                  <Error>
                                    {errors.educationDetails[index].yearCompleted}
                                  </Error>
                                )}
                            </InputContainer>
                            <InputContainer>
                              <label htmlFor="">upload certificate</label>
                              <FormUploader
                                form
                                accept={[
                                  'application/pdf',
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg'
                                ]}
                                className={
                                  touched?.educationDetails?.[index]?.cert &&
                                  errors?.educationDetails?.[index]?.cert
                                    ? 'Invalid'
                                    : ''
                                }
                                handleUpload={(files) => {
                                  const modifiedFile = files[0];
                                  // const modifiedFile = renameFileUpload(
                                  //   file,
                                  //   fileName || 'certificate'
                                  // );
                                  setFieldValue(
                                    `educationDetails.${index}.cert`,
                                    modifiedFile
                                  );
                                }}
                                text="Upload File"
                                // file={newInfo?.academic?.cert}
                                file={values?.educationDetails?.[index]?.cert}
                              />
                              {touched?.educationDetails?.[index]?.cert &&
                              errors?.educationDetails?.[index]?.cert ? (
                                <Error>{errors?.educationDetails?.[index]?.cert}</Error>
                              ) : null}
                            </InputContainer>

                            <AddSection>
                              <AddSection>
                                {index > 0 && (
                                  <button
                                    className="text-red"
                                    bgColor="transparent"
                                    textColor="#000"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    REMOVE
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => {
                                    push({
                                      name: '',
                                      degree: '',
                                      major: '',
                                      grade: '',
                                      location: '',
                                      yearStarted: '',
                                      yearCompleted: '',
                                      cert: ''
                                    });
                                  }}
                                >
                                  + Add Education
                                </button>
                              </AddSection>
                            </AddSection>
                          </div>
                        ))}
                    </>
                  )}
                />
                <Button type="submit" disabled={showLoader ? true : false}>
                  {showLoader ? (
                    <div style={{ textAlign: 'center' }}>
                      <Loader />
                    </div>
                  ) : (
                    'Next'
                  )}
                </Button>
              </Form>
            </AcademicForm>
          </Container>
        );
      }}
    />
  );
};

export default Academic;
