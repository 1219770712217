import styled, { css } from 'styled-components';

export const ButtonContainer = styled.button`
  width: ${(props) => (props.auto ? 'auto' : '100%')};
  height: 48px;
  background: ${(props) => (props.type === 'cancel' ? '#fff' : '#333333')};
  border-radius: 2px;
  border: ${(props) => (props.type === 'cancel' ? '1px solid red' : 'none')};
  cursor: pointer;
  color: #fff;
  color: ${(props) => (props.type === 'cancel' ? 'red' : '#fff')};
  text-align: center;
  font-size: 1.25rem;
  padding: ${({ auto }) => (auto ? '12px 35px' : '0')};
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.2;
  }
  ${({ candidates }) => (candidates ? candidatePadding : actionNavBar)};
  ${({ backbutton }) => (backbutton ? backButton : null)};
`;

const candidatePadding = css`
  padding: 10px 20px;
  margin-left: 10px;
`;

const actionNavBar = css`
  padding: 12px 35px;
`;

const backButton = css`
  background: #ffffff;
  color: #333333;
  border: 1px solid #ebebeb;
  border-radius: 2px;
`;
