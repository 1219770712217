import styled, { keyframes } from 'styled-components';

export const BlueLinkButton = styled.button`
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  font-size: 14px;
  color: #408996;
  -webkit-tap-highlight-color: unset;
  &:focus {
    color: green;
    text-decoration: none;
    background: none;
    border: none;
  }
  &:active {
    color: green;
    text-decoration: none;
    background: none;
    border: none;
  }
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SavingButton = styled.button`
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  font-size: 14px;
  color: #408996;
  cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }
`;
export const UpdatingButton = styled.button`
  background-color: #2185d0;
  min-height: 1rem;
  color: #fff;
  border-radius: 3px;
  padding: 0.6rem 1rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: #1a6fb1;
    transition: all 1s;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
export const WhiteButton = styled.button`
  background-color: #fff;
  min-height: 1rem;
  color: #fff;
  border-radius: 3px;
  padding: 0.6rem 1rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};

  &:disabled {
    cursor: not-allowed;
  }
`;

export const LoadingButton = styled.div`
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

export const RequestActionBar = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 2px;
  padding: 20px;
  align-items: center;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 32px;
`;

export const UserInfoDetails = styled.div`
  align-items: center;
  margin-left: 30px;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
  .email {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
  }
`;

export const FormCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 2px;
  margin-bottom: 10px;
`;

export const FormCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fcfcfc;
  padding: 15px 29px;
`;
export const GreenLinkButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: green;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    background: none;
    text-decoration: none;
  }
`;
export const GreenButton = styled.button`
  background-color: #4d909d;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const FormCardHeaderAction = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #408996;
  cursor: pointer;
  margin: 0;
`;

export const FormCardBody = styled.div`
  background: #ffffff;
  padding: 10px 29px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  padding: ${(props) => props?.padding};
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #999999;
`;

export const Input = styled.input`
  width: 100%;
  background: #e5e5e5;
  border: none;
  padding: 20px;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  ${'' /* text-transform: capitalize; */}
  color: #666666;
  &:disabled {
    background: transparent;
  }
`;
export const InputB = styled.input`
  border-radius: 5px;
  height: 30px;
  border: 1px solid #ccc;
  min-width: 250px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-left: 0.6rem;
  &:disabled {
    background: transparent;
  }
`;

export const FormCardFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const UploadBar = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  column-gap: 10px;
  border: 1px solid rgba(64, 137, 150, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
  padding: 5px;
  margin: 20px 0px 10px 20px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #408996;
    margin: 0 5px;
  }
`;

export const SelectDropDown = styled.select`
  border: 1px solid rgba(64, 137, 150, 0.25);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: ${(props) => props?.width || ''};
`;

export const ClientInfoRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 7fr;
  margin-bottom: 10px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
  }
`;

export const ModalLabel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
`;

export const TimeLine = styled.span`
  margin-bottom: 20px;
`;
export const ModalBody = styled.p`
  align-items: center;
  text-align: center;
  width: 65%;
  margin: 0 auto 20px auto;
`;

export const FooterOptionContainer = styled.div`
  text-align: right;
  padding-right: 20px;
`;

export const FooterButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
`;

export const FileInput = styled.div`
  outline: none;
  border-radius: 2px;
  display: flex;
  background: #f9f9f9;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  label {
    margin: 10px;
    color: ${({ isUploaded }) => (isUploaded ? 'black' : '#919191')};
  }
`;

export const Caption = styled.label`
  text-transform: uppercase;
  color: #666;
  font-weight: bold;
  font-size: 12px;
  display: grid;
  align-items: end;
  margin-bottom: 10px;
`;

export const TextArea = styled.textarea`
  background: #f9f9f9;
  border-radius: 4px;
  height: 120px;
  width: 100%;
  padding: 11px 15px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  font-feature-settings: 'kern' off;
  outline: none;
  color: #333333;
  border: ${({ error }) => (error ? '1px solid red' : 'none')};
`;

export const Form = styled.form``;

export const TextInputWrapper = styled.div`
  margin: 10px 0 20px;
`;

export const CheckRowDiv = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
  margin-bottom: 10px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f5f5f5;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
`;
