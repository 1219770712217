import styled from 'styled-components';
import { svgs } from 'assets';

export const FormCardTitle = styled.div`
  h3 {
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 0.625rem;
  }

  p {
    font-weight: 200;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;

  span {
    display: block;
    margin-bottom: 1.5rem;
  }
`;

export const Message = styled.div`
  width: 100%;
  height: 3rem;
  padding: 1rem;
  display: block;
  background-color: ${({ type }) =>
    type === 'success' ? 'rgba(52, 185, 145, 0.1)' : 'rgba(205, 49, 10, 0.1)'};
  font-size: 1rem;
  color: ${({ type }) => (type === 'success' ? 'green' : 'red')};
  border-radius: 2px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Error = styled.div`
  color: red;
  font-size: 1.2rem;
  margin-top: 0.7rem;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 18px 0;
  position: relative;

  label {
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .InputElement {
    outline: none;
    border: 0.5px solid #999999;
    background-color: #fff;
    font-size: 1.5rem;
    padding: 0.375rem 1rem;
    display: block;
    width: 100%;
    height: 3.3rem;
    border-radius: 2px;
    color: #333333;
    font-weight: 600;
    font-size: 1.1rem;

    &:focus {
      outline: none;
      border: 2px solid #34b991;
    }
  }

  .Invalid {
    border: 2px solid red;
    background: rgba(244, 67, 54, 0.1);

    &:focus {
      outline: none;
      border: 2px solid red;
    }
  }
`;

export const Password = styled.div`
  width: 2rem;
  height: 2rem;
  font-weight: 700;
  font-size: 0.9rem;
  position: absolute;
  top: 47%;
  right: 5%;
  cursor: pointer;
`;

export const ForgotPasswordLink = styled.a`
  font-size: 1.4rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  margin-top: 1rem;
  justify-self: center;
  align-self: center;
  text-align: center;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    color: #34b991;
  }
`;

export const Warning = styled(svgs.WARNING)`
  margin-right: 0.5rem;
  padding: 0 0.3rem;
`;

export const Close = styled(svgs.WARNING)`
  margin-right: 0.5rem;
  padding: 0 0.4rem;
  cursor: pointer;
`;
export const Eye = styled(svgs.EYE)`
  width: 2rem;
  height: 2rem;
`;

export const HideEye = styled(svgs.HIDE_EYE)`
  width: 2rem;
  height: 2rem;
`;
