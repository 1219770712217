import React from 'react';
import Layout from './Layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastSuccess = (icon, message) => {
  toast.success(<Layout icon={icon} message={message} />, {
    position: toast.POSITION.BOTTOM_CENTER
  });
};

export const toastError = (icon, message) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
};
