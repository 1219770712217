import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { resetPasswordValidationSchema } from '../../helpers/validators';
import Button from '../../components/common/Button';
import AuthBackground from 'components/common/AuthBackground';
import Loader from 'components/common/Loader';
import FormCard from 'components/common/FormCard';
import { RESET_PASSWORD } from 'graphql/resolvers/auth/mutations';
import { svgs } from 'assets';
import decodeJWT from 'jwt-decode';
import {
  LoginLink,
  FormCardTitle,
  Form,
  InputContainer,
  Error,
  Message,
  Password,
  Success
} from '../SetPassword/setPassword';
import { useParams } from 'react-router-dom';
import { Warning, Close } from 'pages/Signin/styles';

const CreatePassword = ({ history, match }) => {
  const payload = history.location.search.split('&');
  const token = payload[0].split('?token=')[1];
  const email = payload[1].split('email=')[1];

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordIcon = showPassword ? (
    <svgs.EYE style={{ width: '2rem', height: '2rem' }} />
  ) : (
    <svgs.HIDE_EYE style={{ width: '2rem', height: '2rem' }} />
  );

  const [resetPassword, { data, error, loading }] = useMutation(RESET_PASSWORD);

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      ...resetPasswordValidationSchema
    }),
    onSubmit: async (values) => {
      let newPassword = values.password;
      try {
        await resetPassword({ variables: { newPassword, token } });
        setTimeout(() => {
          history.push('/success', {
            isValid: true,
            message: 'Password created successfully'
          });
        }, 3000);
      } catch (err) {
        console.log('A problem occurred, please try again.');
      }
    }
  });
  return (
    <AuthBackground>
      <FormCard type="reset">
        <svgs.LOGO className="logo" />
        <FormCardTitle>
          <h3>Create a password</h3>
          <p>Please create a new password for</p>
          <p className="email">{email}</p>
        </FormCardTitle>
        {loading ? (
          <Loader fullpage={true} small={true} />
        ) : error && error.graphQLErrors[0].message ? (
          <Message>
            <Warning />
            {error.graphQLErrors[0].message}
            <Close />
          </Message>
        ) : data && data.success ? (
          <Success>{data.success}</Success>
        ) : (
          ''
        )}
        <Form onSubmit={formik.handleSubmit}>
          <InputContainer>
            <label htmlFor="password">password</label>
            <input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={`InputElement ${
                formik.touched.password && formik.errors.password ? 'Invalid' : ''
              }`}
            />
            <Password onClick={togglePassword}>{passwordIcon}</Password>
            {formik.touched.password && formik.errors.password ? (
              <Error>
                <Warning />
                {formik.errors.password}
              </Error>
            ) : null}
          </InputContainer>
          <span></span>
          <Button type="submit">Create New password</Button>
        </Form>
      </FormCard>
      <LoginLink href="/signin">Back to Login</LoginLink>
    </AuthBackground>
  );
};

export default CreatePassword;
