import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_CREDIT } from 'graphql/resolvers/admin/candidates/mutations';
import * as Yup from 'yup';
import { creditValidationSchema } from 'helpers/validators';

import { InputContainer, Form } from '../../Admin/Clients/styles';
import { AcademicForm, FormFooter } from '../styles';
import { Error } from 'pages/Signin/styles';
import { toastSuccess, toastError } from 'components/common/Toast';
import { renameFileUpload } from 'utils/renameUploadUtils';

import Container from 'components/common/Container';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import FormUploader from 'components/Uploader';
import { svgs } from 'assets';
import { Select } from './style';

const identicationOptions = [
  'NIN',
  'DRIVERS LICENCE',
  'INTL PASSPORT',
  'PERMANENT VOTERS CARD'
];

const Credit = ({
  nextTab,
  prevTab,
  index,
  newInfo,
  setNewinfo,
  savedData,
  fileName,
  ...props
}) => {
  const checkFile = (file) => {
    if (file) {
      return file;
    } else {
      return null;
    }
  };
  const [showLoader, setLoaderState] = useState(false);
  const [showidFront, setShowidFront] = useState(checkFile(newInfo?.credit?.idFront));
  const [showidBack, setShowidBack] = useState(checkFile(newInfo?.credit?.idBack));

  const [selectIdentification, setSelectIdentification] = useState(
    identicationOptions[0]
  );
  const [createCreditRequest] = useMutation(CREATE_CREDIT);

  const handleidFront = (files) => {
    const modifiedFile = files[0];
    // const modifiedFile = renameFileUpload(file, fileName || 'idFront');
    setShowidFront(modifiedFile);
  };
  const handleidBack = (files) => {
    const modifiedFile = files[0];
    // const modifiedFile = renameFileUpload(file, fileName || 'idBack');
    setShowidBack(modifiedFile);
  };
  const handleSelectChange = (e) => {
    setSelectIdentification(e.target.value);
  };

  const handleClick = () => {
    formik.setFieldValue('meansOfIdentification', selectIdentification);
  };
  const formik = useFormik({
    initialValues: {
      id: '',
      meansOfIdentification: identicationOptions[0],
      bvn: '',
      phoneNumber: '',
      dob: '',
      idNumber: '',
      ...savedData[savedData.length - 1],
      ...newInfo?.credit
    },
    validationSchema: Yup.object({
      ...creditValidationSchema
    }),
    onSubmit: async (values) => {
      setLoaderState(true);
      try {
        const response = await createCreditRequest({
          variables: {
            verificationRequest: localStorage.getItem('verificationId'),
            id: values.id ? values.id : null,
            bvn: values.bvn,
            phoneNumber: values.phoneNumber,
            dob: values.dob,
            meansOfIdentification: values.meansOfIdentification,
            idNumber: values.idNumber,
            idFront: !showidFront ? values?.idFront : showidFront,
            idBack: !showidBack ? values?.idBack : showidBack
          }
        });
        const { ok, errors } = response.data.createCreditRequest;
        setLoaderState(false);
        if (errors) {
          const { messages } = errors;
          return toastError('', messages);
        }
        const valueModified = { ...values, idFront: showidFront, idBack: showidBack };
        if (!errors & ok) {
          setNewinfo((prev) => ({
            ...prev,
            credit: valueModified
          }));
          toastSuccess(svgs.SUCCESS_ICON, 'Credit Request Submitted Successfully');
          nextTab(index);
        }
      } catch (error) {
        const { message } = error;
        toastError(svgs.DELETE_ICON, message);
      }
      return setLoaderState(false);
    }
  });

  return (
    <Container>
      <AcademicForm reference>
        <Form onSubmit={formik.handleSubmit}>
          <div style={{ padding: '30px' }}>
            <InputContainer>
              <label htmlFor="BVN">BVN of candidate</label>
              <input
                id="bvn"
                name="bvn"
                type="text"
                placeholder="BVN"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bvn}
                className={`InputElement ${
                  formik.touched.address && formik.errors.bvn ? 'Invalid' : ''
                }`}
              />
              {formik.touched.bvn && formik.errors.bvn ? (
                <Error>{formik.errors.bvn}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                placeholder="Phone Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className={`InputElement ${
                  formik.touched.phoneNumber && formik.errors.phoneNumber ? 'Invalid' : ''
                }`}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <Error>{formik.errors.phoneNumber}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="date">Date for birth</label>
              <input
                id="dob"
                name="dob"
                type="date"
                placeholder="YYYY-MM-DD"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dob}
                className={`InputElement ${
                  formik.touched.dob && formik.errors.dob ? 'Invalid' : ''
                }`}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <Error>{formik.errors.dob}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="date">Means of Identification</label>
              <Select
                value={formik.values.meansOfIdentification}
                onChange={handleSelectChange}
                onClick={handleClick}
                id="meansOfIdentification"
                name="meansOfIdentification"
              >
                {identicationOptions?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <label htmlFor="landmark">Identification Number</label>
              <input
                id="idNumber"
                name="idNumber"
                type="text"
                placeholder="Identification Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idNumber}
                className={`InputElement ${
                  formik.touched.idNumber && formik.errors.idNumber ? 'Invalid' : ''
                }`}
              />
              {formik.touched.idNumber && formik.errors.idNumber ? (
                <Error>{formik.errors.idNumber}</Error>
              ) : null}
            </InputContainer>

            <InputContainer>
              <label htmlFor="">upload Identification front </label>
              <FormUploader
                form
                name="idFront"
                accept={[
                  'application/pdf',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'image/png',
                  'image/jpg',
                  'image/jpeg'
                ]}
                handleUpload={handleidFront}
                text="Upload File"
                file={formik.values?.idFront}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="">upload Identification Back</label>
              <FormUploader
                form
                name="passport"
                accept={[
                  'application/pdf',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'image/png',
                  'image/jpg',
                  'image/jpeg'
                ]}
                handleUpload={handleidBack}
                text="Upload File"
                file={formik.values?.idBack}
              />
            </InputContainer>
          </div>

          <FormFooter>
            <Button backbutton onClick={() => prevTab(index)} type="button">
              Back
            </Button>
            <Button type="submit">
              {showLoader ? (
                <div style={{ textAlign: 'center' }}>
                  <Loader />
                </div>
              ) : (
                'Next'
              )}
            </Button>
          </FormFooter>
        </Form>
      </AcademicForm>
    </Container>
  );
};

export default Credit;
