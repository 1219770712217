import gql from 'graphql-tag';

export const VERIFY_CANDIDATE = gql`
  query verifyCandidateToken($token: String!) {
    verifyCandidateToken(token: $token) {
      id
      requestTypes
      status
      candidate {
        id
        firstname
        lastname
      }
      consentRequest {
        id
        file
      }
      socialMediaRequest {
        id
        url
        handle
        status
      }
      creditRequest {
        id
        bvn
        phoneNumber
        dob
        meansOfIdentification
        idNumber
        idFront
        idBack
        status
      }
      acadRequest {
        nameOfInstitution
        qualification
        yearCompleted
        location
        yearStarted
        grade
        courseOfStudy
        id
        upload
        confirmationDoc
        status
        consent
      }
      refRequest {
        id
        name
        email
        officialEmail
        phone
        address
        status
        officeAddress
        confirmationDoc
      }
      addressRequest {
        address
        contactPerson
        phoneNumber
        nearestBusStop
        nearestLandmark
        state
        city
        localGovernment
        id
        status
        confirmationDoc
      }
      profRequest {
        awardBody
        certificateName
        certificateNumber
        consent
        upload
        dateAwarded
        id
        confirmationDoc
        status
      }
      criminalRequest {
        fingerprintCapturingDate
        fingerprintCapturingVenue
        confirmationDoc
        passport
        id
        status
        race
      }
      nyscRequest {
        callNumber
        certificateNumber
        certificateType
        confirmationDoc
        groundsForExemption
        upload
        dateCompleted
        dateStarted
        id
        status
      }
      guarantorRequest {
        id
        address
        officialEmail
        officialPhone
        officialAddress
        status
        role
        phone
        name
        upload
        confirmationDoc
        email
        organization
      }
      employeeRequest {
        employerName
        contactPerson
        contactPersonRole
        phone
        status
        consent
        confirmationDoc
        id
        address
        email
      }
      olevelRequest {
        id
        certificate
        confirmationDoc
        examName
        examNumber
        type
        year
        status
      }
    }
  }
`;

export const GET_CONSENT_DOCS = gql`
  query documentList {
    documentList {
      totalCount
      results {
        id
        name
        upload
      }
    }
  }
`;
