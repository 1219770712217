import { authMutations, authQueries } from './auth';

export const resolvers = {
  Mutation: {
    ...authMutations,
  },
  Query: {
    ...authQueries,
  },
};
