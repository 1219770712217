import styled, { css } from 'styled-components';

const Login = css`
  width: 38.75rem;
  height: 43rem;
`;

const Forgot = css`
  width: 40.75rem;
  height: 35.625rem;
`;

const Resend = css`
  width: 40.75rem;
  height: 30.625rem;
`;

export const FormCardContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 3.75rem 5rem;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ type }) => (type === 'login' ? Login : type === 'reset' ? Forgot : Resend)}
  .logo {
    margin-bottom: 1.2rem;
  }
`;
