import * as React from 'react';
import { Redirect, Route } from 'react-router';
import {accessRules} from '../../../constants/accessRules';
import { checkPermissions } from 'helpers/checkPermissions';

/**
 * Returns authenticated route
 *
 * @param {Object} props
 *
 * @returns {JSX}
 */
const AuthorizedRoute = (props) => {
  const {fallback, redirectTo, children, role, action} = props;
  if (checkPermissions(accessRules, role, action)) {
    return (
      <Route { ...props } />
    );
  }

  if (fallback) {
    return fallback;
  }

  return (
    <Redirect to={redirectTo} />
  );
};

AuthorizedRoute.defaultProps = {
  redirectTo: '/signin',
};

export default AuthorizedRoute;
