import styled from 'styled-components';

export const Select = styled.select`
  background: #fafafa;
  border: none;
  color: #000000;
  padding: 10px;
  border-style: none;
  outline-color: grey;
  option {
    color: grey;
    background: #fafafa;
  }
`;
