import React, { useState, useRef, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { GET_AUTH_USER_QUERY } from 'graphql/resolvers/auth/queries';
import {
  HeaderContainer,
  LogoContainer,
  NavigationContainer,
  ProfileNavContainer,
  Nav,
  NotificationCount,
  NotificationIconContainer,
  ProfileImage,
  ProfileName,
  Dropdown,
  DOWN_ICON
} from './styles';

import { svgs } from '../../assets';
import { useAuth } from 'context/authContext';
import Restrict from 'components/common/Restrict';

const Header = ({ children, type }) => {
  const { removeAuthUser } = useAuth();
  const [display, setDisplay] = useState(false);
  const ref = useRef();
  const { LOGO, AVATAR, NOTIFICATION_ICON } = svgs;
  const { user, currentClient } = useAuth();

  const toggleDropdown = () => {
    setDisplay(!display);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplay(!display);
      }
    };
    if (display) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [display, ref]);

  return (
    <HeaderContainer>
      <LogoContainer>
        <LOGO />
        {currentClient && <p className="ml-4 my-0">{currentClient.client.name}</p>}
      </LogoContainer>
      <NavigationContainer>
        <Nav>{children}</Nav>
      </NavigationContainer>
      <ProfileNavContainer>
        {!type ? (
          ''
        ) : (
          <NotificationIconContainer>
            <img src={NOTIFICATION_ICON} alt="Notifications" style={{ width: 16 }} />
            <NotificationCount>2</NotificationCount>
          </NotificationIconContainer>
        )}
        <Dropdown>
          <ProfileImage>
            <img
              src={user.image ? user.image : svgs.EMPTY_AVATAR}
              alt="ProfileImage"
              style={{ width: 26 }}
            />
          </ProfileImage>
          <ProfileName onClick={toggleDropdown}>
            {user
              ? `${user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1)} ${
                  user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1)
                }`
              : ''}
            <DOWN_ICON display={display} />
          </ProfileName>
          <div ref={ref} className={`dropdown-content ${display ? 'show' : ''}`}>
            <Restrict role={user?.roles[0]} action={['adnin-setting:view']}>
              <NavLink to="/admin/settings" onClick={toggleDropdown}>
                Settings
              </NavLink>
            </Restrict>
            <p onClick={() => removeAuthUser()}>Logout</p>
          </div>
        </Dropdown>
      </ProfileNavContainer>
    </HeaderContainer>
  );
};

export default Header;
